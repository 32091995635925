import React from 'react';
import ReactMarkdown from 'react-markdown';

import SupportCard from '../SupportCard';

const SupportWeOffer = ({ title, verticalText, supportItems = [] }) => {
    return (
        <div className="relative flexibility bg-white py-16 md:py-20 xl:py-28">
            <div className="container mx-auto px-10 relative">
                <div className="grid grid-cols-1 lg:grid-cols-14">
                    <div className="hidden xl:block">
                        <div className="vert-label">{verticalText}</div>
                    </div>
                    <div className="col-span-full xl:col-span-12">
                        <h2 className="career-styled-heading text-5xl md:text-4xl lg:text-5xl xl:text-6xl font-extrabold uppercase"><ReactMarkdown children={title} /></h2>
                        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mt-12 lg:mt-16">
                            {supportItems.map((item, i) => <SupportCard {...item} />)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SupportWeOffer;