import React from "react";
import Img from "gatsby-image";
import { format } from "date-fns";

import { getModelSlug } from "../../config/helpers";
import { useSearchState } from "../../context";

import Link from "../Link";
import LinkWithIcon from "../LinkWithIcon";

import "./style.scss";

const Featured = ({
  feature: {
    title,
    slug,
    heroImage,
    meta,
    model: { name: modelName },
  },
  linkText,
  showDate,
  backgroundColour,
  verticalLabel,
}) => {
  const { query } = useSearchState();
  const createdAt = format(new Date(meta?.createdAt), "MMMM dd / yyyy");

  return (
    query === "" && (
      <div
        className={`featured pt-12 md:pt-16 relative ${
          backgroundColour === "Grey" ? "bg-bare" : "bg-white"
        }`}
      >
        <div className="featured__background w-full h-full absolute top-0 left-0 z-0 overflow-hidden" />
        <div className="container mx-auto px-10 relative z-10">
          <div className="lg:grid lg:grid-cols-14 lg:gap-4">
            <div className="featured__image relative flex items-center col-span-7">
              <Link
                to={`${getModelSlug(modelName)}/${slug}`}
                className="block w-full relative"
              >
                <Img
                  className="w-full"
                  fluid={[
                    heroImage?.mobile,
                    {
                      ...heroImage?.desktop,
                      media: "(min-width: 768px)",
                    },
                  ]}
                  style={{ position: null }}
                />
              </Link>
            </div>
            <div className="flex flex-col items-start justify-center col-span-14 md:col-span-6 xl:col-span-5 md:col-start-9 xl:col-start-9 py-8 lg:py-0">
              {showDate && (
                <span className="font-bold text-secondary">{createdAt}</span>
              )}
              <h2
                className={`line-styled-heading max-w-xs lg:max-w-md mb-8 md:mb-12 pr-8 md:pr-12 lg:pr-0 relative text-4xl ${
                  showDate ? "mt-4 md:mt-8" : "lg:text-5xl 2xl:text-6xl"
                } font-extrabold uppercase`}
              >
                {title}
              </h2>
              <LinkWithIcon
                to={`${getModelSlug(modelName)}/${slug}`}
                text={linkText}
                icon="arrowRight"
                className="featured__link relative"
              />
            </div>
            {verticalLabel && (
              <div className="hidden xl:flex items-start justify-end">
                <div className="vert-label">{verticalLabel}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  );
};

export default Featured;
