import Button from "../Button"
import PressReleaseCard from "../PressReleaseCard"

import { useState } from "react"
import "./styles.scss"
import React from "react"
import { Formik, Field, Form, FormikHelpers } from "formik"
import { CountryDropdown } from "react-country-region-selector"
import UploadFile from "../Formik/UploadFile"
import ClipLoader from "react-spinners/ClipLoader"
import ReCAPTCHA from "react-google-recaptcha"

const PublishingPitchForm = ({
  heading,
  subHeading,
  sideTag,
  description,
  submitButtonText,
}) => {
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  const handleSubmit = async (values, setSubmitting) => {
    setError(false)
    setSuccess(false)
    if (values.recaptcha && values.recaptcha.length > 0) {
      const data = {
        title: `${values.name} - NDreams Publishing`,
        organization_id: parseInt(
          process.env.GATSBY_PIPEDRIVE_ORGANIZATION_ID ?? ""
        ),
        b82fba98fe5bf7296ebf24c5cdda75e3b2bce2fc: values.name,
        "03bb1dc3e2b79fc33c4740efdcd11461026d29e9": values.companyName,
        f3b3532fc876ded964585993848a13aaa25e0aa5: values.email,
        fb53c3ee64b7671d13d4e17e8524fad47c37864c: values.projectName,
        b22d0e752db44a3448ac0f015fd45d1807c0554b: values.country,
        fad1dd6f6d434be9e559d59a77ca972e0940b75e: values.websiteUrl,
        "111f4ae85ff9b028e0aeed2ab687f0d197bb4387": values.extraLinks,
        "9d794d8c06456bbea0c7d1cba2d5edadf5788bb2": values.message,
      }
      try {
        // Content-Type must be specified as "application/json" or Pipedrive API fails
        const response = await fetch(
          `https://api.pipedrive.com/v1/leads?api_token=${process.env.GATSBY_PIPEDRIVE_API_TOKEN}`,
          {
            body: JSON.stringify(data),
            headers: { "Content-Type": "application/json" },
            method: "POST",
          }
        ).then((res) => {
          return res.json()
        })
        if (response.success) {
          if (response.data.id) {
            if (values.upload) {
              const formData = new FormData()
              formData.append("lead_id", response.data.id)
              formData.append("file", values.upload)
              try {
                // do not specify the Content-Type, the Pipedrive API fails if you do so
                const response = await fetch(
                  `https://api.pipedrive.com/v1/files?api_token=${process.env.GATSBY_PIPEDRIVE_API_TOKEN}`,
                  {
                    body: formData,
                    method: "POST",
                  }
                ).then((res) => {
                  return res.json()
                })
                if (response.success) {
                  setSubmitting(false)
                  setError(false)
                  setSuccess(true)
                } else {
                  setSubmitting(false)
                  setError(
                    "An error occurred with the file upload. Please try again later."
                  )
                  setSuccess(false)
                }
              } catch (err) {
                setSubmitting(false)
                setError(
                  "An error occurred with the file upload. Please try again later."
                )
                setSuccess(false)
              }
            } else {
              setSubmitting(false)
              setSuccess(true)
            }
          } else {
            setSuccess(false)
            setSubmitting(false)
            setError(
              "An error occurred with form submission, Please try again."
            )
          }
        } else {
          setSubmitting(false)
          setError(
            "An error occurred with the file upload. Please try again later."
          )
          setSuccess(false)
        }
      } catch (err) {
        setSubmitting(false)
        setError(
          "An error occurred with the submission. Please try again later."
        )
        setSuccess(false)
      }
    } else {
      setSubmitting(false)
      setError("Please submit ReCaptcha")
      setSuccess(false)
    }
  }

  return (
    <div
      id="publishing-pitch-form"
      className="py-12 md:py-16 xl:py-28 bg-secondary relative"
    >
      <div className="container mx-auto relative px-10">
        <div className="grid grid-cols-1 md:grid-cols-14">
          <div className="col-span-full xl:col-span-12 xl:col-start-2">
            <div className="flex flex-start md:mb-12 items-start gap-10">
              <div className="hidden md:flex sideways-text uppercase rotate-180 justify-end mr-4 font-semibold text-md mt-8 md:mt-0">
                {sideTag}
              </div>
              <div>
                <h2 className="flex-1 text-3xl md:text-4xl lg:text-5xl font-bold text-bare uppercase">
                  {heading}
                </h2>
                <h2 className="flex-1 text-3xl md:text-4xl lg:text-5xl font-bold text-black uppercase">
                  {subHeading}
                </h2>
                <p className="text-white max-w-xl mt-8">{description}</p>
              </div>
            </div>
            <div className="flex">
              <div className="mt-8 md:mt-0 w-full">
                <Formik
                  initialValues={{
                    name: "",
                    companyName: "",
                    email: "",
                    projectName: "",
                    country: "",
                    websiteUrl: "",
                    extraLinks: "",
                    upload: "",
                    message: "",
                    recaptcha: "",
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    handleSubmit(values, setSubmitting)
                  }}
                >
                  {({
                    values,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    errors,
                    isSubmitting,
                  }) => (
                    <Form>
                      <fieldset className="publishing-form grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 w-full">
                        <div className="col-span-1 mb-6 border-b !border-dashed border-white ">
                          <Field
                            id="name"
                            name="name"
                            required
                            placeholder="Name*"
                            className="w-full bg-transparent focus:outline-none text-white placeholder:text-white text-xl py-4 "
                          />
                        </div>

                        <div className="col-span-1 mb-6 border-b !border-dashed border-white ">
                          <Field
                            id="companyName"
                            name="companyName"
                            required
                            placeholder="Company Name*"
                            className="w-full bg-transparent text-white placeholder:text-white text-xl py-4 focus:outline-none"
                          />
                        </div>
                        <div className="col-span-1 w-full mb-6 border-b !border-dashed border-white ">
                          <Field
                            id="email"
                            name="email"
                            required
                            placeholder="Email Address*"
                            type="email"
                            className="w-full bg-transparent text-white placeholder:text-white text-xl py-4 focus:outline-none"
                          />
                        </div>
                        <div className="col-span-1 mb-6 border-b !border-dashed border-white ">
                          <Field
                            id="projectName"
                            name="projectName"
                            required
                            placeholder="Game/Project Name*"
                            className="w-full bg-transparent text-white placeholder:text-white text-xl py-4 focus:outline-none"
                          />
                        </div>
                        <div className="col-span-1 mb-6 border-b !border-dashed border-white ">
                          <CountryDropdown
                            name="country"
                            required
                            value={values.country}
                            onChange={(_, e) => {
                              handleChange(e)
                            }}
                            defaultOptionLabel="Country*"
                            onBlur={handleBlur}
                            className="country-dropdown w-full bg-transparent text-white placeholder:text-white text-xl py-4 focus:outline-none"
                          />
                        </div>
                        <div className="col-span-1 mb-6 border-b !border-dashed border-white ">
                          <Field
                            id="websiteUrl"
                            name="websiteUrl"
                            placeholder="Website URL"
                            className="w-full bg-transparent text-white placeholder:text-white text-xl py-4 focus:outline-none"
                          />
                        </div>
                        <div className="col-span-1 mb-6 border-b !border-dashed border-white ">
                          <Field
                            id="extraLinks"
                            name="extraLinks"
                            placeholder="Extra Links (Dropbox, YouTube, etc)"
                            className="w-full bg-transparent text-white placeholder:text-white text-xl py-4 focus:outline-none"
                          />
                        </div>
                        <div className="col-span-1 mb-6 border-b !border-dashed border-white ">
                          <UploadFile
                            name="upload"
                            data={values}
                            errors={errors}
                            setFieldValue={setFieldValue}
                          />
                        </div>
                        <div className="col-span-1 md:col-span-2 lg:col-span-3 mb-6  border-b !border-dashed border-white ">
                          <Field
                            as="textarea"
                            id="message"
                            required
                            name="message"
                            placeholder="Message*"
                            className="w-full bg-transparent text-white placeholder:text-white text-xl py-4 min-h-[150px] focus:outline-none"
                          />
                        </div>
                      </fieldset>
                      <ReCAPTCHA
                        sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
                        onChange={(e) => {
                          setFieldValue("recaptcha", e)
                        }}
                        className="mb-4"
                      />
                      <Button
                        type="submit"
                        className="!bg-black !border-black min-w-[114px] min-h-[48px] flex justify-center items-center"
                        clickHandler={() => {}}
                      >
                        {isSubmitting ? (
                          <ClipLoader
                            color="#ffffff"
                            size={25}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          />
                        ) : (
                          "Submit"
                        )}
                      </Button>
                      {error && (
                        <div className="text-white uppercase font-bold mt-4">
                          {error}
                        </div>
                      )}
                      {success && (
                        <div className="text-white uppercase font-bold mt-4">
                          Successfully submitted, we will be in touch soon!
                        </div>
                      )}
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PublishingPitchForm
