import React from 'react'
import Img from 'gatsby-image'

import Link from '../Link'
import LinkWithIcon from '../LinkWithIcon'

const GameCard = ({ title, slug, heroImage, logo, secondaryColour }) => (
    <Link
        to={`/games/${slug}`}
        className="relative link-with-icon-hover flex flex-col group"
    >
        <div className="relative">
            <Img fluid={heroImage.fluid} />
            {secondaryColour?.hex && (
                <span
                    className="block w-full h-full absolute top-0 left-0 opacity-0 invisible group-hover:visible group-hover:opacity-50 transition-all duration-200"
                    style={{ backgroundColor: secondaryColour.hex }}
                />
            )}
            <Img
                fluid={logo.fluid}
                className="w-7/12 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                style={{ position: 'absolute' }}
            />
        </div>
        <div className="flex flex-col sm:flex-row flex-1 sm:items-center sm:justify-between py-8 lg:pr-8 xl:pr-12">
            <h3 className="line-styled-heading flex-0 md:max-w-xs sm:pr-8 md:pr-20 text-3xl md:text-4xl font-extrabold uppercase">
                {title}
            </h3>
            <LinkWithIcon
                color="mi-dark"
                clickHandler={() => true}
                text="See more"
                icon="arrowRight"
                className="mt-4 sm:mt-0 sm:whitespace-nowrap"
            />
        </div>
    </Link>
)

export default GameCard
