import React from 'react'
import Img from 'gatsby-image'
import ReactMarkdown from 'react-markdown'

const AwardsShowcase = ({
    heading,
    awards = [],
    verticalLabel,
}) => awards.length > 0 && (
    <div className="py-12 md:py-16 xl:py-28 bg-white relative">
        <div className="container mx-auto relative px-10">
            <div className="grid grid-cols-1 md:grid-cols-14">
                {verticalLabel && (
                    <div className="hidden xl:block">
                        <div className="vert-label">{verticalLabel}</div>
                    </div>
                )}
                <div className={verticalLabel ? 'col-span-full xl:col-span-12' : 'col-span-full xl:col-span-12 xl:col-start-2'}>
                    <h2 className="line-styled-heading text-4xl sm:text-5xl lg:text-6xl 2xl:text-7xl text-secondary font-extrabold uppercase"><ReactMarkdown children={heading} /></h2>
                    <div className="grid grid-cols-full md:grid-cols-2 lg:grid-cols-4 text-xl">
                        {awards.map(({ year, awardingBody, awardName, for: awardedFor, image }, i) => (
                            <div key={i} className={`flex md:flex-col justify-between items-center md:items-start pb-8 md:pb-0 md:px-8 mt-8 md:mt-12 lg:mt-20 ${(i < awards.length-1) ? 'border-b' : ''} md:border-b-0 md:border-l border-dashed border-aldo`}>
                                <div className="md:flex-1">
                                    <span className="font-light">{year}</span>
                                    <h4 className="text-primary font-bold uppercase">{awardingBody}</h4>
                                    <p className="mt-2 text-lg">{awardName}</p>
                                    {awardedFor && <p className="text-lg text-boulder">{awardedFor}</p>}
                                </div>
                                {image && (
                                    <div className="w-5/12 sm:w-1/3 md:w-full md:max-w-md ml-6 md:ml-0 md:mt-6 md:pr-20 lg:pr-8 2xl:pr-20 relative">
                                        <Img fluid={image.fluid} />
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    </div>
)

export default AwardsShowcase
