import React from 'react'
import ReactMarkdown from 'react-markdown'
import { useStaticQuery, graphql } from 'gatsby'

import PostCard from '../PostCard'
import LinkWithIcon from '../LinkWithIcon'

import './style.scss'

const GetAllLatestPosts = () => {
    const { posts: { nodes }} = useStaticQuery(graphql`
        query getAllLatestPosts {
            posts: allDatoCmsPost(sort: {order: DESC, fields: meta___createdAt}, limit: 3) {
                nodes {
                    title
                    slug
                    excerpt
                    heroImage {
                        mobile: fluid(imgixParams: {auto: "format", w: "720", h: "440", fit: "crop"}) {
                            base64
                            height
                            width
                            src
                            srcSet
                            aspectRatio
                        }
                    }
                    meta {
                        createdAt
                    }
                }
            }
        }
    `)

    return nodes
}

const LatestNews = ({
	heading,
	posts = [],
	game,
	verticalLabel,
}) => {
	const allPosts = game?.posts || (posts.length > 0 ? posts : false) || GetAllLatestPosts()

	return allPosts.length > 0 && (
		<div className="latest-news py-12 md:py-16 xl:py-28">
			<div className="container mx-auto px-10">
				<div className="grid grid-cols-1 md:grid-cols-14">
					<div className="hidden xl:block">
						<div className="vert-label">{verticalLabel}</div>
					</div>
					<div className="col-span-full xl:col-span-12">
						<h2 className={`${game?.primaryColour?.hex ? 'game-styled-heading' : 'line-styled-heading'} text-5xl md:text-4xl lg:text-5xl xl:text-6xl font-extrabold uppercase`} style={{ color: game?.primaryColour?.hex }}><ReactMarkdown children={heading} /></h2>
						<div className="latest-news__cards grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-4 mt-12">
							{ allPosts.map((post, i) => ((game?.posts && i < 3) || !game?.posts) && (
								<PostCard { ...{ key: i, ...post, game } } className="mt-6 md:mt-0 latest-news__col" />
							))}
						</div>
					</div>
					<div className="col-span-full xl:col-span-13 mt-8 md:mt-12 text-center md:text-right">
						<LinkWithIcon color={game?.primaryColour?.hex ? 'mi-black' : 'mi-dark'} to="/news" text="See all articles" icon="arrowRight" />
					</div>
				</div>
			</div>
		</div>
	)
}

export default LatestNews
