import React, { useState } from 'react';
import cx from 'classnames';
import ReactMarkdown from 'react-markdown';
import Icon from '../../Icon';

const FlexibleCard = ({ title, body, className, border }) => {
    const [show, setShow] = useState(false);

    return (
        <div
            className={cx(`flexibility__card cursor-pointer transform px-10 lg:px-0 transition-all box-border xl:py-6 ${show ? 'scale-105 bg-bare' : 'scale-100'}`, className)}
            onMouseEnter={() => setShow(true)}
            onMouseLeave={() => setShow(false)}
            onClick={() => setShow(!show)}>
            <div className={`${border && 'lg:border-r'} ${show ? '!pb-4' : 'pb-10'} lg:px-4 py-6 border-b lg:border-b-0 border-bare border-dashed xl:px-8`}>
                <div className="flex flex-row flex-nowrap justify-between lg:flex-col lg:gap-6">
                    <h3 className={`text-5xl md:text-4xl lg:text-4xl transition-color uppercase ${show ? '!text-black' : 'text-white'} flexible-styled-heading`}>
                        <ReactMarkdown children={title} />
                    </h3>
                    <div className={`w-6 h-6 flex items-center justify-center ${show ? 'lg:hidden' : 'block'}`}>
                        {show ? (
                            <Icon {...{
                                name: 'minus',
                                clickHandler: () => setShow(false),
                                className: show ? 'block lg:hidden text-black' : 'hidden',
                            }} />
                        ) : (
                            <Icon {...{
                                name: 'plus',
                                clickHandler: () => setShow(!show),
                                className: `${show ? 'hidden' : 'block text-white'}`
                            }} />
                        )}
                    </div>

                </div>
                <ReactMarkdown children={body} className={`transition-all ${show ? 'block opacity-100 text-black mt-8' : 'hidden opacity-0'}`} />
            </div>
        </div>
    )
}

export default FlexibleCard;