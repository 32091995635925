import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import ReactMarkdown from 'react-markdown'

import Videos from './components/Videos'
import Images from './components/Images'

import './style.scss'

const GetAllMedia = () => {
    const { quotes, videos } = useStaticQuery(graphql`
        query getAllGameMedia {
            quotes: allDatoCmsGameQuote {
                edges {
                    node {
                        body
                        author
                        game {
                            id
                        }
                    }
                }
            }
            videos: allDatoCmsGameVideo {
                edges {
                    node {
                        heading
                        game {
                            id
                        }
                        video {
                            provider
                            providerUid
                            thumbnailUrl
                            title
                            url
                        }
                        image {
                            fluid(
                                imgixParams: {
                                    auto: "format"
                                    w: "680"
                                    h: "400"
                                    fit: "crop"
                                }
                            ) {
                                base64
                                height
                                width
                                src
                                srcSet
                                aspectRatio
                            }
                        }
                    }
                }
            }
        }
    `)

    return {
        quotes: quotes?.edges || [],
        videos: videos?.edges || [],
    }
}

const MediaShowcase = ({
    heading,
    video,
    game,
    showVideos,
    showImages,
    images = [],
}) => {
    const media = GetAllMedia()
    const videos = (showVideos ? media?.videos : []).filter(
        ({ node }) => node?.game?.id === game?.id
    )
    const quotes = (media?.quotes || []).filter(
        ({ node }) => node?.game?.id === game?.id
    )
    const [activeQuote, setActiveQuote] = useState(0)

    useEffect(() => {
        if (quotes.length > 1) {
            let interval = null
            interval = setInterval(() => {
                setActiveQuote(
                    activeQuote + 1 === quotes.length ? 0 : activeQuote + 1
                )
            }, 6000)

            return () => clearInterval(interval)
        }
    }, [quotes, activeQuote, setActiveQuote])

    return (
        <div className="media-showcase py-12 md:py-16 xl:py-28 bg-blue-dark relative overflow-hidden">
            <div className="container mx-auto px-10 relative z-20">
                <div className="grid md:grid-cols-14">
                    <div className="col-span-full xl:col-span-12 xl:col-start-2">
                        <h2
                            className={`${
                                game
                                    ? 'media-showcase__game-heading'
                                    : 'line-styled-heading'
                            } mb-8 md:mb-12 relative text-4xl sm:text-5xl lg:text-6xl font-extrabold uppercase`}
                            style={{ color: game?.primaryColour?.hex }}
                        >
                            <ReactMarkdown children={heading} />
                        </h2>
                        <div className="grid lg:grid-cols-5 lg:gap-4">
                            {quotes?.length > 0 && (
                                <div className="media-showcase__quotes flex flex-col items-start lg:col-span-2 p-8 md:p-12 relative bg-blue-light text-white">
                                    <div className="w-full h-56 lg:h-full relative">
                                        {quotes.map(
                                            ({ node: { body, author } }, i) => (
                                                <div
                                                    className={`absolute top-0 left-0 ${
                                                        i === activeQuote
                                                            ? 'visible opacity-100'
                                                            : 'invisible opacity-0'
                                                    } transition-all duration-600`}
                                                >
                                                    <p className="text-3xl md:text-4xl lg:text-3xl xl:text-4xl font-light">
                                                        "{body}"
                                                    </p>
                                                    <p
                                                        className="mt-4 md:mt-8 pt-4 md:mt-8 border-t border-dashed text-xl font-semibold uppercase"
                                                        style={{
                                                            borderColor:
                                                                game
                                                                    ?.primaryColour
                                                                    ?.hex,
                                                        }}
                                                    >
                                                        {author}
                                                    </p>
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>
                            )}
                            {video?.url && (
                                <div
                                    className={`block ${
                                        quotes?.length > 0
                                            ? 'col-span-3'
                                            : 'col-span-full'
                                    }`}
                                >
                                    <div className="pt-9/16 relative">
                                        <iframe
                                            type="text/html"
                                            src={`https://www.youtube.com/embed/${video.providerUid}?modestbranding=1&rel=0&playsinline=0&allowfullscreen=true`}
                                            frameBorder="0"
                                            className="w-full h-full absolute top-0 left-0"
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {showVideos && videos.length > 0 && (
                    <Videos
                        {...{
                            videos: videos.map(({ node }) => ({ ...node })),
                            game,
                        }}
                    />
                )}
                {showImages && images.length > 0 && (
                    <Images {...{ images, game }} />
                )}
            </div>
        </div>
    )
}

export default MediaShowcase
