import React from 'react';
import ReactMarkdown from 'react-markdown';
import FlexibleCard from './FlexibleCard';

import './styles.scss';


const FlexibleWorking = ({ title, sideText, flexibilities = [] }) => {

    return (
        <div className={`relative flexibility bg-primary text-white py-16 md:py-20 xl:py-28`}>
            <div className="flexibility__background w-full h-full absolute top-0 left-0 z-0 overflow-hidden" />
            <div className="container mx-auto lg:px-10 relative">
                <div className="md:grid md:grid-cols-14">
                    <div className="hidden xl:block">
                        <div className="vert-label">{sideText}</div>
                    </div>
                    <div className="col-span-full xl:col-span-12">
                        <h2 className={`px-10 lg:px-0 flexible-styled-heading text-5xl md:text-4xl lg:text-5xl xl:text-6xl font-extrabold uppercase`}><ReactMarkdown children={title} /></h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-6 lg:gap-0 mt-12">
                            {flexibilities.map(({ title, body }, i) => {
                                return (
                                    <FlexibleCard {...{
                                        className: ``,
                                        title,
                                        body,
                                        border: i < flexibilities.length - 1 ? true : false
                                    }} />
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FlexibleWorking