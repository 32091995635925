import { FunctionComponent } from "react"
import { FormikErrors } from "formik"
import React, { useState, useEffect } from "react"
import Icon from "../Icon"
import { el } from "date-fns/locale"

// interface IUploadFile {
//   data: { image?: File };
//   setFieldValue: (
//     field: string,
//     value: any,
//     shouldValidate?: boolean | undefined
//   ) => Promise<FormikErrors<{ image?: File }>> | Promise<void>;
//   errors: FormikErrors<{ image?: File }>;
// }

const UploadFile = ({ data, setFieldValue, errors, name }) => {
  const [hasFile, setHasFile] = useState(false)
  return (
    <>
      <input
        type="file"
        name={name}
        id={name}
        // set supported file types here,
        // could also check again within formik validation or backend
        accept=""
        onChange={(e) => {
          // Object is possibly null error w/o check
          if (e.currentTarget.files) {
            setFieldValue(name, e.currentTarget.files[0])
            if (e.currentTarget.files.length > 0) {
              setHasFile(true)
            } else {
              setHasFile(false)
            }
          } else {
            setHasFile(false)
          }
        }}
        className="w-[0.1px] h-[0.1px] opacity-0 overflow-hidden absolute z-[-1]"
      />
      {errors.image && (
        <>
          <br />
          <span id="error">{errors.image}</span>
          <br />
        </>
      )}
      <label
        for={name}
        className="flex items-center h-full relative w-full bg-transparent text-white placeholder:text-white text-xl py-4 cursor-pointer"
      >
        {hasFile && data.upload.name ? (
          <div className="overflow-hidden text-ellipsis">
            {data.upload.name}
          </div>
        ) : (
          <div>
            Upload
            <span className="absolute right-0 bottom-4">
              <Icon name="plus" className="text-white" />
            </span>
          </div>
        )}
      </label>
    </>
  )
}

export default UploadFile
