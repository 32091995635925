import React, { useState } from 'react';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge'
import BackgroundImage from 'gatsby-background-image';
import ReactMarkdown from 'react-markdown';
import Icon from '../Icon';

const PartnerCard = ({ title, body, backgroundImage, textColour }) => {
    const [selected, setSelected] = useState(false);

    const image = getImage(backgroundImage.gatsbyImageData);
    const background = convertToBgImage(image);

    const colour = textColour ? JSON.parse(textColour) : 'text-black';

    return (
        <div className={`${selected ? 'scale-105' : 'scale-100'} transition-transform`}>
            <BackgroundImage
                Tag="div"
                {...background}
                className={`cursor-pointer`}
            >
                <div className={`min-h-[325px] lg:min-h-[550px] xl:min-h-[650px] h-full p-10 w-full ${colour} hover:text-white !z-20 relative`} onClick={() => setSelected(!selected)} onMouseOver={() => setSelected(true)} onMouseOut={() => setSelected(false)}>
                    <div className="flex flex-row items-center justify-between mb-6">
                        <h3 className="uppercase font-extrabold text-3xl max-w-[200px]"><ReactMarkdown children={title} /></h3>
                        {selected ? (
                            <Icon {...{
                                name: 'cross',
                            }} />
                        ) : (
                            <Icon {...{
                                name: 'plus',
                            }} />
                        )}
                    </div>
                    <ReactMarkdown children={body} className={`lg:mr-6 ${selected ? 'opacity-100' : 'opacity-0'} transition-opacity`} />
                </div>
                <div className="bg-gradient-to-b from-black/20 to-transparent !bg-opacity-10 absolute top-0 left-0 w-full h-1/2 !z-10" />
            </BackgroundImage>
        </div>
    )
}

export default PartnerCard;