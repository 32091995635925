import React, { useState } from 'react'
import Img from 'gatsby-image'

import { useLightboxState } from '../../context'

import LinkWithIcon from '../LinkWithIcon'
import Icon from '../Icon'

import './style.scss'

const Card = ({
	title,
	video,
	secondaryColour,
	heroImage,
	onMouseEnter,
	onMouseLeave,
	handleVideoClick,
}) => (
	<div { ...{
		className: `inline-flex flex-col link-with-icon-hover group ${video ? 'cursor-pointer' : 'cursor-auto'}`,
		onMouseEnter,
		onMouseLeave,
		onClick: () => video ? handleVideoClick(video) : true,
	}}>
		<div className="relative">
			<Img
				tag="card-image"
				fluid={heroImage.mobile}
			/>
			{secondaryColour?.hex && <span className="block w-full h-full absolute top-0 left-0 opacity-0 invisible group-hover:visible group-hover:opacity-50 transition-all duration-200" style={{ backgroundColor: secondaryColour.hex }} />}
		</div>
        <div className="flex-1 flex flex-col justify-between py-8">
            <h2 className="w-1/2 md:w-1/3 line-styled-heading text-3xl font-extrabold uppercase">{title}</h2>
            {video?.url && <LinkWithIcon color="mi-dark" clickHandler={() => true} text="Play video" icon="play" className="mt-4 md:mt-6 text-md" />}
	    </div>
	</div>
)

const PreviousReleaseCarousel = ({
    heading,
	games = [],
}) => {
	const { setLightboxVideo, setLightboxOpen } = useLightboxState()
	const [ index, setIndex ] = useState(0)
	const [ direction, setDirection ] = useState(1)

	const navigate = ({ forceDirection = null }) => {
		const offset = (window.innerWidth > 768) ? (window.innerWidth > 1280) ? 3 : 3 : 2

		if (!forceDirection) {
			if (index === 1 && direction === 0) {
				setDirection(1)
			} else if (index === (games.length - offset) && direction === 1) {
				setDirection(0)
			}
		}

		if (forceDirection === 0 || ((forceDirection === null) && direction === 0)) setIndex(Math.max(0, index - 1))
		if (forceDirection === 1 || ((forceDirection === null) && direction === 1)) setIndex(Math.min(games.length - (offset-1), index + 1))
	}

	const handleVideoClick = (video) => {
        setLightboxVideo(video)
        setLightboxOpen(true)
    }

	const handlePrevClick = () => {
		navigate({ forceDirection: 0 })
	}

	const handleNextClick = () => {
		navigate({ forceDirection: 1 })
	}

	return (
		<div className="previous-release-carousel py-12 md:py-16 relative bg-bare overflow-hidden">
			<div className="container mx-auto relative z-20">
            	<div className="grid grid-cols-14 px-10">
                    <h2 className="col-span-full xl:col-start-2 mb-8 md:mb-12 text-3xl xl:text-4xl text-black font-bold uppercase">{heading}</h2>
					<div className={`previous-release-carousel__slider grid grid-flow-col grid-cols-14 gap-4 col-span-full 2xl:col-span-8 xl:col-start-2 2xl:col-start-2 overflow-x-visible transition duration-500 slide-${index}`}>
						{ games.map((game, i) => (
							<Card
								{ ...{
									key: i,
									handleVideoClick,
									...game
								} }
							/>
						))}
					</div>
					<div className="flex items-center justify-start col-span-full xl:col-span-12 xl:col-start-2 mt-8 md:mt-12 text-center md:text-right">
						<div className="-ml-4 p-4 cursor-pointer" onClick={() => handlePrevClick()}>
							<Icon name="arrowLeft" />
						</div>
						<div className="p-4 ml-4 cursor-pointer" onClick={() => handleNextClick()}>
							<Icon name="arrowRight" />
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default PreviousReleaseCarousel
