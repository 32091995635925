import React, { useRef, useState, useEffect } from 'react'
import BackgroundImage from 'gatsby-background-image'
import ReactMarkdown from 'react-markdown'

import LinkWithIcon from '../LinkWithIcon'

import './style.scss'

const ModalGrid = ({
    heading,
    image,
    modals = [],
}) => {
    const gridRef = useRef(null)
    const [ active, setActive ] = useState(-1)

    const handleClick = (e) => {
        if (gridRef?.current?.contains(e?.target)) {
            return true
        } else {
            setActive(-1)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClick);

        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, [ gridRef ]);

    return modals.length > 0 && (
        <div className="modal-grid py-16 xl:py-28 bg-black relative overflow-hidden">
            <BackgroundImage
                tag="background"
                className="w-full h-full absolute top-0 left-0 bg-black bg-cover bg-center bg-no-repeat z-0"
                fluid={image.fluid}
                style={{ position: null }}
            />
            <div className="w-full h-full absolute top-0 left-0 bg-black opacity-30 z-10" />
            <div className="container mx-auto relative px-10 z-20">
                <div className="grid grid-cols-1 md:grid-cols-14">
                    <div className="md:col-span-full xl:col-span-12 xl:col-start-2">
                        <h2 className="mb-8 md:mb-12 line-styled-heading max-w-lg text-4xl md:text-5xl lg:text-6xl text-white font-extrabold"><ReactMarkdown children={heading} /></h2>
                        <div ref={gridRef} className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                            {modals.map(({ heading, body }, i) => (
                                <div key={i} className={`modal-grid__modal link-with-icon-hover relative md:static text-white border border-dashed ${(active === i) ? 'shadow-2xl md:shadow-none bg-primary md:bg-transparent md:border-white-20 border-primary' : 'border-white'} `}>
                                    <div className={`flex items-center justify-between px-8 md:px-12 pt-12 md:p-12 ${(active === i) ? 'md:opacity-20' : 'pb-12 md:opacity-100'} cursor-pointer`} onClick={() => setActive((i === active) ? -1 : i)}>
                                        <h3 className="text-2xl font-extrabold uppercase">{heading}</h3>
                                        <LinkWithIcon icon={(i === active) ? 'minus' : 'plus'} color="mi-light" className="ml-4 md:ml-8" clickHandler={() => true} />
                                    </div>
                                    <div className={`w-full md:max-w-xl xl:max-w-2xl p-8 md:p-12 xl:p-16 md:absolute top-0 md:top-1/2 left-1/12 md:left-1/2 md:transform md:-translate-x-1/2 md:-translate-y-1/2 md:bg-primary transition-all duration-600 md:shadow-2xl md:scale-95 ${(active === i) ? 'block opacity-100 visible pointer-events-auto md:scale-100 z-30' : 'hidden md:block opacity-0 invisible pointer-events-none z-20'}`}>
                                        <div className="hidden md:flex items-center justify-between mb-4 md:mb-8">
                                            <span className="text-3xl md:text-4xl font-extrabold uppercase">{heading}</span>
                                            <LinkWithIcon icon="minus" color="mi-light" className="ml-4 md:ml-8" clickHandler={() => setActive(-1)} />
                                        </div>
                                        <p>{body}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalGrid
