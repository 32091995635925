import React from 'react'
import Img from 'gatsby-image'
import ReactMarkdown from 'react-markdown'

import { getModelSlug } from '../../config/helpers'
import { useLightboxState } from '../../context'

import LinkWithIcon from '../LinkWithIcon'
import Link from '../Link'

import './style.scss'

const ImageWithText = ({
    backgroundColour,
    image,
    imagePosition,
    imageText,
    video,
    heading,
    body,
    showLink,
    scrollToElement,
    elementId,
    link,
    linkText,
    playButtonColour,
    verticalLabel,
}) => {
    const { setLightboxVideo, setLightboxOpen } = useLightboxState()

    const handleVideoClick = () => {
        setLightboxVideo(video)
        setLightboxOpen(true)
    }

    const handleScrollTop = (e) => {
        e.preventDefault()

        const elmnt = document.getElementById(elementId)
        if ( typeof elmnt?.scrollIntoView === 'function') {
            elmnt.scrollIntoView({ behavior: 'smooth' })
        }
    }

    return (
        <div className={`image-with-text relative ${backgroundColour === 'Grey' ? 'bg-bare' : 'bg-white'}`}>
            <div className="container mx-auto px-10 relative">
                <div className="md:grid md:grid-cols-14 md:grid-flow-col">
                    {verticalLabel && (
                        <div className={`py-12 md:py-16 xl:py-28 hidden ${imagePosition === 'Right' ? 'xl:block' : 'xl:flex items-start justify-end' }`}>
                            <div className="vert-label">{verticalLabel}</div>
                        </div>
                    )}
                    <div className={`flex flex-col items-start justify-center py-12 md:py-16 xl:py-28 col-span-14 md:col-span-6 xl:col-span-5 ${imagePosition === 'Left' ? 'md:col-start-9 xl:col-start-9' : 'xl:col-start-2'} 2xl:pr-4`}>
                        {heading && (
                            <h2 className="line-styled-heading mb-8 md:mb-12 relative text-4xl sm:text-5xl lg:text-6xl 2xl:text-7xl font-extrabold uppercase"><ReactMarkdown children={heading} /></h2>
                        )}
                        {body && <ReactMarkdown children={body} className="wysiwyg" />}
                        {(showLink && (link || (scrollToElement && elementId))) && (
                            <LinkWithIcon
                                clickHandler={(e) => scrollToElement ? handleScrollTop(e) : true}
                                to={(link?.slug && link?.model?.name) ? `${getModelSlug(link?.model?.name)}/${link?.slug}` : null}
                                text={linkText}
                                icon="arrowRight"
                                className="mt-8 md:mt-12"
                            />
                        )}
                    </div>
                    <div className={`mb-12 md:mb-0 col-span-7 ${imagePosition === 'Right' ? 'col-start-8' : 'col-start-1'}`}>
                        <div className="relative">
                            <Img
                                className="w-full relative"
                                fluid={[
                                    image.lowRes,
                                    {
                                        ...image.highRes,
                                        media: '(min-width: 1024px)',
                                    },
                                ]}
                                style={{ position: null }}
                            />
                            {video && (
                                <Link clickHandler={() => handleVideoClick()} className="link-with-icon-hover flex items-center justify-center w-full h-full absolute top-0 left-0">
                                    {imageText && (
                                        <h3 className="md:hidden lg:block video-with-text__image-text w-full p-8 absolute bottom-0 right-0 text-4xl sm:text-5xl md:text-4xl 2xl:text-5xl text-right text-white font-extrabold uppercase">
                                            <span className="line-styled-heading inline-block max-w-xs xl:max-w-sm">{imageText}</span>
                                        </h3>
                                    )}
                                    <LinkWithIcon icon="play" color={playButtonColour === 'White' ? 'mi-light' : 'mi-dark'} clickHandler={() => true} />
                                </Link>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ImageWithText
