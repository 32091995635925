import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import ReactLottie from 'react-lottie'

import animations from './animations'
import './style.scss'

const mapAnimation = (name) => {
    switch (name) {
        case 'Fist Bump':
            return animations.fistBump;
        case 'Planet':
            return animations.planet;
        case 'Reload':
            return animations.reload;
        case 'Superhero':
            return animations.superhero;
        case 'Heart':
            return animations.heart;
        default:
            return animations.fistBump;
    }
}

const CardsWithAnimation = ({
    heading,
    body,
    cards = [],
    verticalLabel,
}) => {
    const [ activeIndex, setActiveIndex ] = useState(-1)

    return cards.length > 0 && (
        <div className="py-12 md:py-16 xl:py-28 bg-bare relative">
            <div className="container mx-auto relative px-10">
                <div className="grid grid-cols-1 md:grid-cols-14">
                    {verticalLabel && (
                        <div className="hidden xl:block">
                            <div className="vert-label">{verticalLabel}</div>
                        </div>
                    )}
                    <div className={verticalLabel ? 'col-span-full xl:col-span-12' : 'col-span-full xl:col-span-12 xl:col-start-2'}>
                        <div className="grid md:grid-cols-2">
                            <h2 className="line-styled-heading mb-8 md:mb-0 line-styled-heading max-w-md text-4xl md:text-5xl lg:text-6xl text-black font-extrabold uppercase"><ReactMarkdown children={heading} /></h2>
                            <ReactMarkdown children={body} className="flex items-center md:pl-12 lg:pl-20 md:border-l md:border-aldo md:border-dashed wysiwyg" />
                        </div>
                        <div className="mt-12 md:mt-20 grid sm:grid-cols-2 md:grid-cols-3 2xl:grid-cols-5 gap-4">
                            {cards.map(({ heading: cardHeading, body: cardBody, animation }, i) => (
                                <div
                                    key={i}
                                    className="group cards-with-animation__card h-96 relative border border-aldo hover:border-primary border-dashed text-center"
                                    onMouseEnter={() => setActiveIndex(i)}
                                    onMouseLeave={() => setActiveIndex(-1)}
                                >
                                    <div className="cards-with-animation__card-inner flex flex-col h-96 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 group-hover:bg-primary group-hover:shadow-lg">
                                        <div className="cards-with-animation__animation-wrapper group-hover:block group-hover:flex-none group-hover:mx-auto flex-1 flex flex-col items-center justify-center group-hover:pb-0 text-2xl font-extrabold">
                                            <div className="cards-with-animation__animation mx-auto pointer-events-none">
                                                { (activeIndex === i) ? (
                                                    <ReactLottie
                                                        { ...{
                                                            isStopped: (activeIndex !== i),
                                                            isPaused: false,
                                                            options: {
                                                                loop: true,
                                                                autoplay: true,
                                                                animationData: mapAnimation(animation)?.animation,
                                                                rendererSettings: { preserveAspectRatio: 'none' }
                                                            },
                                                            speed: 1,
                                                            className: 'w-32 h-32',
                                                        } }
                                                    />
                                                ) : (
                                                    <img src={mapAnimation(animation)?.icon} className="w-32" alt={cardHeading} />
                                                )}
                                            </div>
                                        </div>
                                        <div className="border-t border-aldo border-dashed group-hover:border-primary">
                                            <h3 className="cards-with-animation__heading p-8 group-hover:pt-4 text-2xl uppercase"><ReactMarkdown children={cardHeading} /></h3>
                                            <div className="max-h-0 group-hover:max-h-80 overflow-hidden">
                                                <p className="px-8 text-lg text-white">{cardBody}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardsWithAnimation
