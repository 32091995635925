import React, { useEffect, useRef, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { useSearchState } from '../../context'

import PostCard from '../PostCard'
import Button from '../Button'

import './style.scss'

const GetAllPosts = () => {
    const { posts: { nodes }} = useStaticQuery(graphql`
        query getAllPosts {
            posts: allDatoCmsPost(sort: {order: DESC, fields: meta___createdAt}) {
                nodes {
                    title
                    slug
                    excerpt
                    heroImage {
                        mobile: fluid(imgixParams: {auto: "format", w: "720", h: "440", fit: "crop"}) {
                            base64
                            height
                            width
                            src
                            srcSet
                            aspectRatio
                        }
                    }
                    meta {
                        createdAt
                    }
                }
            }
        }
    `)

    return nodes
}

const PostsGrid = ({
    posts = [],
    showAll,
    verticalLabel,
}) => {
    const resultsRef = useRef(null)
    const { query } = useSearchState()
    const [ showFirst, setShowFirst ] = useState(showAll ? 6 : 99)
    const allPosts = (showAll ? GetAllPosts() : posts).filter(({ title }) => title.toLowerCase().includes(query.toLowerCase()))

    useEffect(() => {
        if (query !== '' && resultsRef?.current) {
            window.scrollTo(0, resultsRef?.current?.getBoundingClientRect()?.y - 80)
        }
    }, [ query ])

    return (
        <div ref={resultsRef} className="posts-grid pt-12 md:py-16 xl:py-28 bg-bare relative">
            <div className="container mx-auto relative px-10">
                <div className="grid grid-cols-1 md:grid-cols-14">
                    {verticalLabel && (
                        <div className="hidden xl:block">
                            <div className="vert-label">{query !== '' ? query : verticalLabel}</div>
                        </div>
                    )}
                    <div className={verticalLabel ? 'col-span-full xl:col-span-12' : 'col-span-full xl:col-span-12 xl:col-start-2'}>
                        <div className="posts-grid__cards grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-4">
                            {allPosts.length > 0 ? (
                                <>
                                    {allPosts.map((post, i) => (i < showFirst) && (
                                        <PostCard { ...{ key: i, ...post } } />
                                    ))}
                                </>
                            ) : (
                                <h2 className="col-span-full line-styled-heading max-w-lg mx-auto text-4xl sm:text-5xl text-black font-extrabold text-center uppercase">We couldn't find anything...</h2>
                            )}
                        </div>
                    </div>
                </div>
                {(showAll && showFirst < allPosts.length) && (
                    <div className="mt-8 md:mt-12 text-center">
                        <Button className="mx-auto hover:bg-black hover:border-black" clickHandler={() => setShowFirst(showFirst + 6)}>Load more</Button>
                    </div>
                )}
            </div>
        </div>
    )
}

export default PostsGrid
