import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import GameCard from '../GameCard'
import Button from '../Button'

const GetAllGames = () => {
    const { allDatoCmsGame: { nodes }} = useStaticQuery(graphql`
        query getAllGames {
            allDatoCmsGame(sort: {fields: releaseDate, order: DESC}) {
                nodes {
                    title
                    slug
                    secondaryColour {
                        hex
                    }
                    logo {
                        fluid {
                            base64
                            height
                            width
                            src
                            srcSet
                            aspectRatio
                        }
                    }
                    heroImage {
                        fluid(imgixParams: {auto: "format", w: "1080", h: "600", fit: "crop"}) {
                            base64
                            height
                            width
                            src
                            srcSet
                            aspectRatio
                        }
                    }
                }
            }
        }
    `)

    return nodes
}

const GamesGrid = ({
    games = [],
    showAll,
    verticalLabel,
}) => {
    const [ showFirst, setShowFirst ] = useState(showAll ? 4 : 99)
    const allGames = showAll ? GetAllGames() : games

    return allGames.length > 0 && (
        <div className="py-12 md:py-16 xl:py-28 bg-white relative">
            <div className="container mx-auto relative px-10">
                <div className="grid grid-cols-1 md:grid-cols-14">
                    {verticalLabel && (
                        <div className="hidden xl:block">
                            <div className="vert-label">{verticalLabel}</div>
                        </div>
                    )}
                    <div className={verticalLabel ? 'col-span-full xl:col-span-12' : 'col-span-full xl:col-span-12 xl:col-start-2'}>
                        <div className="grid lg:grid-cols-2 gap-4">
                            {allGames.map((game, i) => (i < showFirst) && (
                                <GameCard { ...{ ...game } } />
                            ))}
                        </div>
                    </div>
                </div>
                {(showAll && showFirst < allGames.length) && (
                    <div className="mt-8 md:mt-12 text-center">
                        <Button className="mx-auto hover:bg-black hover:border-black" clickHandler={() => setShowFirst(showFirst + 4)}>Load more</Button>
                    </div>
                )}
            </div>
        </div>
    )
}

export default GamesGrid
