import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useRef } from "react"
import { convertToBgImage } from "gbimage-bridge"
import cx from "classnames"
import { Link } from "gatsby"
import Icon from "../Icon"
import { Swiper, SwiperSlide } from "swiper/react"
import { A11y, Pagination } from "swiper"

import "swiper/css"
import Button from "../Button"
import { getModelSlug } from "../../config/helpers"
import "swiper/css/pagination"

const Slide = ({
  image,
  buttonText,
  description,
  hasButtonLink,
  heading,
  linkedPage,
  className,
}) => {
  const _image = getImage(image.gatsbyImageData)
  const _background = convertToBgImage(_image)

  return (
    <li
      className={cx(
        `w-full h-full bg-transparent relative flex flex-col md:flex-row`,
        className
      )}
    >
      <div className="testimonial-slide relative  flex flex-col justify-center gap-2 md:gap-6 items-center p-3 md:p-6 w-full md:w-2/5 h-full">
        <Icon name="quoteIcon" className="w-12 md:w-20 h-12 md:h-20" />
        <h2 className="uppercase text-2xl md:text-4xl text-center font-bold">
          {heading}
        </h2>
        <p className="text-light-boulder text-center text-sm md:text-md">
          {description}
        </p>
        {hasButtonLink && linkedPage ? (
          <Link
            to={`${getModelSlug(linkedPage.model.name)}/${linkedPage.slug}`}
            className="inline-flex items-center font-bold uppercase cursor-pointer border-2 border-solid outline-none transition duration-200 bg-secondary border-secondary py-2 px-4 md:px-6 text-lg bg-primary border-primary text-white"
          >
            {buttonText}
          </Link>
        ) : null}
      </div>
      <div className="relative w-full md:w-3/5 h-full">
        <GatsbyImage
          image={_image}
          objectFit="cover"
          objectPosition={"center"}
          className=" w-full h-full absolute top-0 left-0"
        />
      </div>
    </li>
  )
}

const PublishingTestimonialsSlider = ({ slides }) => {
  const swiperRef = useRef(null)

  const handlePrev = () => {
    if (swiperRef.current) {
      return swiperRef.current.swiper.slidePrev()
    }
  }

  const handleNext = () => {
    if (swiperRef.current) {
      return swiperRef.current.swiper.slideNext()
    }
  }

  return (
    <div className="!pb-12 md:!pb-16 xl:!pb-28 text-white overflow-x-hidden w-full">
      <div className="container mx-auto w-full">
        <div className=" list-none relative w-full">
          <Swiper
            ref={swiperRef}
            modules={[A11y, Pagination]}
            spaceBetween={20}
            slidesPerView={1}
            centeredSlides={true}
            loop={true}
            className="h-[500px] md:h-[450px]"
            updateOnWindowResize
            pagination={{
              el: ".swiper-testimonials-pagination",
            }}
          >
            {slides.map((slide, i) => (
              <SwiperSlide className="h-full w-full">
                <Slide key={i} {...{ ...slide }} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <div className="grid grid-cols-12 w-full mt-4 gap-4">
        <div className="col-span-12 w-full h-full flex justify-center items-center">
          <div className="swiper-testimonials-pagination flex justify-center items-center gap-1.5 md:gap-2"></div>
        </div>
        <div className="col-span-12 flex items-start justify-between md:justify-start md:gap-10">
          <div
            onClick={() => {
              handlePrev()
            }}
          >
            <Icon name="arrowLeft" className="text-white w-8 cursor-pointer" />
          </div>
          <div
            onClick={() => {
              handleNext()
            }}
          >
            <Icon name="arrowRight" className="text-white w-8 cursor-pointer" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PublishingTestimonialsSlider
