import React, { useState } from 'react'
import BackgroundImage from 'gatsby-background-image'
import ReactMarkdown from 'react-markdown'

import Icon from '../Icon'

import './style.scss'

const FeaturesPanel = ({
    features = [],
}) => {
    const [ active, setActive ] = useState(-1)

    return features.length > 0 &&(
        <div className="bg-black xl:h-128 xl:-mb-8">
            <div className="2xl:container mx-auto xl:px-10">
                <div className="grid grid-flow-row md:grid-cols-2 xl:grid-cols-none xl:grid-flow-col">
                    {features.map(({ heading, body, image, game }, i) => (
                        <div key={i} className={`features-panel__panel ${(active === i) ? 'xl:h-128' : 'xl:h-120'} relative transition-all`} onMouseEnter={() => setActive(i)} onMouseLeave={() => setActive(-1)}>
                            <BackgroundImage
                                tag="background"
                                className="w-full h-full absolute top-0 left-0 bg-black bg-cover bg-right bg-no-repeat z-10"
                                fluid={[
                                    image.sm,
                                    {
                                        ...image.md,
                                        media: '(min-width: 640px)',
                                    },
                                    {
                                        ...image.xl,
                                        media: '(min-width: 1280px)',
                                    },
                                ]}
                                style={{ position: null }}
                            />
                            <div className={`features-panel__colour w-full h-full absolute top-0 left-0 ${((i % 2) === 0) ? 'opacity-90' : 'opacity-70'} xl:opacity-0 bg-cover bg-center bg-no-repeat xl:transition xl:duration-200 z-20`} style={{ backgroundColor: game?.secondaryColour?.hex }} />
                            <div className="features-panel__gradient w-full h-full absolute top-0 left-0 z-10" />
                            <div className={`flex flex-col ${(active === i) ? 'justify-start' : 'justify-end'} ${((i % 2) === 0) ? 'items-start' : 'items-end'} md:items-start xl:items-stretch xl:w-full xl:h-full px-10 py-20 xl:px-8 xl:pb-0 xl:pt-8 relative xl:absolute xl:top-0 xl:left-0 text-white z-30`}>
                                <div className={`flex items-center ${((i % 2) === 0) ? 'justify-start' : 'justify-end'} xl:justify-between`}>
                                    <h2 className="flex-0 features-panel__heading mb-8 text-3xl font-extrabold uppercase"><ReactMarkdown children={heading} /></h2>
                                    {(active !== i) && <Icon name="plus" className="features-panel__plus hidden xl:block ml-4" />}
                                </div>
                                <p className={`${(active === i) ? 'block' : 'xl:hidden' } max-w-sm ${((i % 2) === 0) ? 'text-left' : 'text-right'} md:text-left`}>{body}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default FeaturesPanel
