import React from 'react'

const Editor = ({
    body,
    verticalLabel,
}) => {
    return (
        <div className="editor pt-12 md:py-16 xl:py-28 bg-white relative">
            <div className="container mx-auto relative px-10">
                <div className="grid grid-cols-1 md:grid-cols-14">
                    {verticalLabel && (
                        <div className="hidden xl:block">
                            <div className="vert-label">{verticalLabel}</div>
                        </div>
                    )}
                    <div className={verticalLabel ? 'col-span-full xl:col-span-12' : 'col-span-full xl:col-span-12 xl:col-start-2'}>
                        <div dangerouslySetInnerHTML={{ __html: body }} className="wysiwyg" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Editor
