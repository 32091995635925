import React from 'react';
import ReactMarkdown from 'react-markdown';
import LinkWithIcon from '../LinkWithIcon';
import PartnerCard from '../PartnerCard';

const PartnerSeeking = ({ title, verticalText, partnerItems = [] }) => {

    return (
        <div className="relative bg-white py-16 md:py-20 xl:py-28">
            <div className="container px-10 mx-auto">
                <div className="grid grid-cols-1 lg:grid-cols-14">
                    <div className="hidden xl:block">
                        <div className="vert-label">{verticalText}</div>
                    </div>
                    <div className="col-span-full xl:col-span-12">
                        <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between">
                            <h2 className="career-styled-heading text-5xl md:text-4xl lg:text-5xl xl:text-6xl font-extrabold mb-8 lg:mb-0"><ReactMarkdown children={title} /></h2>
                            <LinkWithIcon {...{
                                text: 'Get in touch',
                                icon: 'arrowRight',
                                color: 'mi-dark',
                                clickHandler: () => {
                                    const form = document.querySelector('#contactForm');
                                    return form.scrollIntoView({ behavior: 'smooth', block: 'start' })
                                }
                            }} />
                        </div>
                        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mt-12 lg:mt-16">
                            {partnerItems.map((item, i) => <PartnerCard {...item} />)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PartnerSeeking;