import React, { useEffect, useRef, useState } from "react"
import cx from "classnames"
import BackgroundImage from "gatsby-background-image"

import LinkWithIcon from "../../LinkWithIcon"

import "./style.scss"

const HeroVideo = ({ heading, image, video: { video } }) => {
  const parentRef = useRef(null)
  const [scrollTop, setScrollTop] = useState(0)
  const [showVideo, setShowVideo] = useState(false)

  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop)
    }
    window.addEventListener("scroll", onScroll)

    return () => window.removeEventListener("scroll", onScroll)
  }, [scrollTop])

  const handleDownClick = (e) => {
    e.preventDefault()

    if (parentRef?.current) {
      window.scrollTo({
        left: 0,
        top:
          parentRef?.current?.getBoundingClientRect()?.y +
          parentRef?.current?.getBoundingClientRect()?.bottom,
        behavior: "smooth",
      })
    }
  }

  return (
    video?.mp4Url && (
      <div
        ref={parentRef}
        className="max-h-screen relative overflow-visible text-center"
      >
        <BackgroundImage
          tag="section"
          className="video-hero w-full relative bg-black bg-cover bg-center bg-no-repeat"
          fluid={[
            image?.mobile,
            {
              ...image?.desktop,
              media: "(min-width: 768px)",
            },
          ]}
        >
          <video
            loop
            autoPlay
            muted
            playsInline
            className="w-full h-full absolute top-0 left-0 z-20"
          >
            <source src={video?.mp4Url} type="video/mp4" />
          </video>
        </BackgroundImage>
        {heading && (
          <h1 className=" absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-4xl sm:text-6xl md:text-7xl lg:text-9xl xl:text-uber text-white font-black uppercase z-30">
            {heading}
          </h1>
        )}
        <LinkWithIcon
          color="mi-light"
          clickHandler={(e) => handleDownClick(e)}
          icon="arrowDown"
          className={`hidden sm:block absolute bottom-12 left-1/2 transform -translate-x-1/2 transition-all duration-800 ${
            scrollTop > 0
              ? "translate-y-4 opacity-0 invisible pointer-events-none"
              : "opacity-100 visible"
          } select-none`}
        />
      </div>
    )
  )
}

export default HeroVideo
