import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { useEffect, useRef } from "react";
import cx from "classnames";
import { convertToBgImage } from "gbimage-bridge";
import BackgroundImage from "gatsby-background-image";
import Icon from "../Icon";
import { gsap } from "gsap";

const AcademyHero = ({ heroImage, academyLogo }) => {
  const arrowsRef = useRef(null);

  const _image = getImage(heroImage.gatsbyImageData);
  const _background = convertToBgImage(_image);
  const _logo = getImage(academyLogo.gatsbyImageData);

  useEffect(() => {
    const ctx = gsap.context(() => {
      gsap.fromTo(
        arrowsRef.current,
        {
          y: 0,
          duration: 2,
          yoyo: true,
        },
        {
          translateY: 10,
          duration: 2,
          repeat: -1,
          yoyo: true,
        }
      );
    });

    return () => ctx.revert();
  }, []);

  const scrollToIntro = () => {
    const ele = document.querySelector(".intro-trigger");

    ele.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  return (
    <BackgroundImage
      {..._background}
      Tag="section"
      style={{
        backgroundPosition: "top center",
      }}
    >
      <div className="relative h-[40vh] md:h-[50vh] lg:h-[60vh] xl:h-[80vh] w-full">
        <span className="bg-gradient-to-t from-black via-transparent to-transparent block w-full h-full absolute z-10 top-0 left-0" />

        <GatsbyImage
          image={_logo}
          alt={academyLogo.alt ? academyLogo : "nDreams Academy Logo"}
          className="!absolute !left-1/2 -translate-x-1/2 !bottom-10 lg:!bottom-32 !z-20  !max-w-sm !max-h-40 lg:!max-h-64 xl:!bottom-44 xl:!max-h-72"
          objectFit="contain"
          objectPosition="center"
        />

        <span
          className="absolute left-1/2 -translate-x-1/2 bottom-2 lg:bottom-20 z-20 text-secondary xl:bottom-32 cursor-pointer"
          ref={arrowsRef}
          onClick={() => scrollToIntro()}
        >
          <Icon name="angleDown" className="max-w-[45px] fill-current" />
          <Icon name="angleDown" className="max-w-[45px] fill-current -mt-1" />
        </span>
      </div>
    </BackgroundImage>
  );
};

export default AcademyHero;
