import React from "react"

import VideoHero from "../Hero/Video"
import VideoWithText from "../VideoWithText"
import FeaturedGame from "../FeaturedGame"
import GamesCarousel from "../GamesCarousel"
import LatestNews from "../LatestNews"
import CareersSpotlight from "../CareersSpotlight"
import CareersArchive from "../CareersArchive"
import ImageHero from "../ImageHero"
import ImageWithText from "../ImageWithText"
import CardsWithAnimation from "../CardsWithAnimation"
import PressReleases from "../PressReleases"
import PublishingWhatWeOffer from "../PublishingWhatWeOffer"
import PublishingTestimonials from "../PublishingTestimonials"
import PublishingProcess from "../PublishingProcess"
import PublishingPitchForm from "../PublishingPitchForm"
import LogoGrid from "../LogoGrid"
import AwardsShowcase from "../AwardsShowcase"
import Featured from "../Featured"
import GamesGrid from "../GamesGrid"
import PreviousReleasesCarousel from "../PreviousReleasesCarousel"
import PostSearchBar from "../PostSearchBar"
import PostsGrid from "../PostsGrid"
import StudioPerksList from "../StudioPerksList"
import ModalGrid from "../ModalGrid"
import FeaturesPanel from "../FeaturesPanel"
import MediaShowcase from "../MediaShowcase"
import Editor from "../Editor"
import InvestingInYou from "../InvestingInYou"
import FlexibleWorking from "../FlexibleWorking"
import PerksAndBenefits from "../PerksAndBenefits"
import SupportWeOffer from "../SupportWeOffer"
import PartnerSeeking from "../PartnerSeeking"
import WorldsBest from "../WorldsBest"
import AcademyHero from "../AcademyHero"
import AcademyHowItWorks from "../AcademyHowItWorks"
import AcademyCareersArchive from "../AcademyCareersArchive"
import Video from "../Video"
import AcademySlider from "../AcademySlider"
import StudioShowcase from "../StudioShowcase"

const getBladeType = ({ key, type, game, props }) => {
  switch (type) {
    case "DatoCmsVideoHero":
      return <VideoHero {...{ key, ...props }} />
    case "DatoCmsVideoWithText":
      return <VideoWithText {...{ key, ...props }} />
    case "DatoCmsFeaturedGame":
      return <FeaturedGame {...{ key, ...props }} />
    case "DatoCmsGamesCarousel":
      return <GamesCarousel {...{ key, ...props }} />
    case "DatoCmsLatestNews":
      return <LatestNews {...{ key, game, ...props }} />
    case "DatoCmsCareersSpotlight":
      return <CareersSpotlight {...{ key, ...props }} />
    case "DatoCmsCareersArchive":
      return <CareersArchive {...{ key, ...props }} />
    case "DatoCmsImageHero":
      return <ImageHero {...{ key, ...props }} />
    case "DatoCmsImageWithText":
      return <ImageWithText {...{ key, ...props }} />
    case "DatoCmsStudioShowcase":
      return <StudioShowcase {...{ key, ...props }} />
    case "DatoCmsCardsWithAnimation":
      return <CardsWithAnimation {...{ key, ...props }} />
    case "DatoCmsLogoGrid":
      return <LogoGrid {...{ key, ...props }} />
    case "DatoCmsAwardsShowcase":
      return <AwardsShowcase {...{ key, ...props }} />
    case "DatoCmsFeatured":
      return <Featured {...{ key, ...props }} />
    case "DatoCmsGamesGrid":
      return <GamesGrid {...{ key, ...props }} />
    case "DatoCmsPreviousReleasesCarousel":
      return <PreviousReleasesCarousel {...{ key, ...props }} />
    case "DatoCmsPostSearchBar":
      return <PostSearchBar {...{ key, ...props }} />
    case "DatoCmsPostsGrid":
      return <PostsGrid {...{ key, ...props }} />
    case "DatoCmsStudioPerksList":
      return <StudioPerksList {...{ key, ...props }} />
    case "DatoCmsModalGrid":
      return <ModalGrid {...{ key, ...props }} />
    case "DatoCmsFeaturesPanel":
      return <FeaturesPanel {...{ key, ...props }} />
    case "DatoCmsMediaShowcase":
      return <MediaShowcase {...{ key, game, ...props }} />
    case "DatoCmsEditor":
      return <Editor {...{ key, ...props }} />
    case "DatoCmsInvestingInCareer":
      return <InvestingInYou {...{ key, ...props }} />
    case "DatoCmsFlexibleWorking":
      return <FlexibleWorking {...{ key, ...props }} />
    case "DatoCmsPerksAndBenefit":
      return <PerksAndBenefits {...{ key, ...props }} />
    case "DatoCmsSupportWeOffer":
      return <SupportWeOffer {...{ key, ...props }} />
    case "DatoCmsWhatWereLookingFor":
      return <PartnerSeeking {...{ key, ...props }} />
    case "DatoCmsWorldsBest":
      return <WorldsBest {...{ key, ...props }} />
    case "DatoCmsAcademyHero":
      return <AcademyHero {...{ key, ...props }} />
    case "DatoCmsAcademyHowItWork":
      return <AcademyHowItWorks {...{ key, ...props }} />
    case "DatoCmsAcademyCareersArchive":
      return <AcademyCareersArchive {...{ key, ...props }} />
    case "DatoCmsAcademySlider":
      return <AcademySlider {...{ key, ...props }} />
    case "DatoCmsPressReleaseBlock":
      return <PressReleases {...{ key, ...props }} />
    case "DatoCmsPublishingWhatWeOfferBlock":
      return <PublishingWhatWeOffer {...{ key, ...props }} />
    case "DatoCmsPublishingTestimonialsBlock":
      return <PublishingTestimonials {...{ key, ...props }} />
    case "DatoCmsPublishingProcessBlock":
      return <PublishingProcess {...{ key, ...props }} />
    case "DatoCmsPublishingPitchFormBlock":
      return <PublishingPitchForm {...{ key, ...props }} />
    case "DatoCmsVideo":
      return <Video {...{ key, ...props }} />
    default:
      return "DEFAULT"
  }
}
const Blades = ({ blades = [], game }) =>
  blades.map(({ __typename: type, ...props }, i) =>
    getBladeType({ key: i, type, game, props })
  )

export default Blades
