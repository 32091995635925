import Button from "../Button"
import PressReleaseCard from "../PressReleaseCard"
import Link from "gatsby"
import { useState, useRef } from "react"
import "./styles.scss"
import React from "react"
import Icon from "../Icon"
import { Swiper, SwiperSlide } from "swiper/react"
import { A11y, Pagination } from "swiper"
import "swiper/css"
import "swiper/css/pagination"
// import "swiper/components/pagination/pagination.min.css"
import { getImage } from "gatsby-plugin-image"
import SVG from "react-inlinesvg"
import { GatsbyImage } from "gatsby-plugin-image"
import { navigate } from "gatsby"

const PublishingProcess = ({
  heading,
  subHeading,
  sideTag,
  description,
  slides,
}) => {
  const swiperRef = useRef(null)

  const handlePrev = () => {
    if (swiperRef.current) {
      return swiperRef.current.swiper.slidePrev()
    }
  }

  const handleNext = () => {
    if (swiperRef.current) {
      return swiperRef.current.swiper.slideNext()
    }
  }
  return (
    <div
      id="publishing-what-we-offer"
      className="py-12 md:py-16 xl:py-28 bg-bare relative"
    >
      <div className="container mx-auto relative px-10">
        <div className="grid grid-cols-1 md:grid-cols-14">
          <div className="col-span-full xl:col-span-12 xl:col-start-2">
            <div className="grid grid-cols-12 mb-12 md:mb-28 items-center w-full">
              <div className="col-span-1 hidden md:flex sideways-text uppercase rotate-180 justify-end mr-4 font-semibold text-md mt-8 md:mt-0">
                {sideTag}
              </div>
              <div className="col-span-12 md:col-span-3 mb-4 md:mb-0">
                <h2 className="flex-1 text-3xl md:text-4xl lg:text-5xl font-bold text-secondary uppercase">
                  {heading}
                </h2>
                <h2 className=" flex-1 text-3xl md:text-4xl lg:text-5xl font-bold text-black uppercase">
                  {subHeading}
                </h2>
              </div>
              <div className="col-span-12 md:col-span-6 md:col-start-7">
                <p>{description}</p>
              </div>
            </div>
            <div className="grid grid-cols-12 relative items-center md:gap-8">
              <div className="col-span-12 relative ml-2 process-slider">
                <Swiper
                  ref={swiperRef}
                  modules={[A11y, Pagination]}
                  spaceBetween={50}
                  slidesPerView={1}
                  centeredSlides={true}
                  updateOnWindowResize
                  pagination={{
                    el: ".swiper-process-pagination",
                  }}
                  loop={true}
                  className="h-[325px] sm:h-[265px] md:h-[308px] xl:h-[400px]"
                >
                  {slides.map(
                    (
                      { icon, headingTag, heading, description, buttonText },
                      i
                    ) => {
                      const _icon = getImage(icon.gatsbyImageData)
                      return (
                        <SwiperSlide className="h-full w-full">
                          <div className=" grid grid-cols-9 h-full w-full">
                            <div className="clip-border-tr-bl col-span-3 w-full relative hidden md:flex h-full flex-col justify-center items-center">
                              <div className="flex items-center justify-center w-3/5 h-full">
                                {icon.format === "svg" ? (
                                  <SVG
                                    src={icon.url}
                                    {...{ className: "w-full" }}
                                  />
                                ) : (
                                  <GatsbyImage
                                    image={_icon}
                                    className="w-full"
                                  />
                                )}
                              </div>
                            </div>
                            <div className="col-span-9 md:col-span-5 md:col-start-5 h-full">
                              <p className="text-boulder uppercase mb-4">
                                {headingTag}
                              </p>
                              <h2 className="uppercase font-bold text-black text-2xl mb-4 line-clamp-2">
                                {heading}
                              </h2>
                              <p className="mb-8 md:mb-16 line-clamp-6 xl:line-clamp-none">
                                {description}
                              </p>

                              <Button
                                className="bg-secondary border-secondary"
                                clickHandler={() => {
                                  document
                                    .getElementById("publishing-pitch-form")
                                    .scrollIntoView({ behaviour: "smooth" })
                                }}
                              >
                                {buttonText}
                              </Button>
                            </div>
                          </div>
                        </SwiperSlide>
                      )
                    }
                  )}
                </Swiper>
              </div>
              <div className="col-span-12 w-full h-full flex justify-center items-center mb-4 mt-2 md:mb-0 md:mt-0">
                <div className="swiper-process-pagination flex justify-center items-center gap-1.5 md:gap-2"></div>
              </div>
              <div className="col-span-12 flex items-start justify-between md:justify-start md:gap-10">
                <div
                  onClick={() => {
                    handlePrev()
                  }}
                >
                  <Icon
                    name="arrowLeft"
                    className="text-black w-8 cursor-pointer"
                  />
                </div>
                <div
                  onClick={() => {
                    handleNext()
                  }}
                >
                  <Icon
                    name="arrowRight"
                    className="text-black w-8 cursor-pointer"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PublishingProcess
