import React, { useState, useEffect } from 'react'

import './style.scss'

const shuffle = (arr) => arr
    .map(a => [Math.random(), a])
    .sort((a, b) => a[0] - b[0])
    .map(a => a[1])

const LogoGrid = ({
    heading,
    logos: allLogos = [],
    padTop,
    verticalLabel,
}) => {
    const [ urls ] = useState(allLogos.map(({ url }) => url))
    const [ activeSet, setActiveSet ] = useState(0)
    const [ logos, setLogos ] = useState([
        shuffle(urls),
        shuffle(urls),
    ])

    const limit = (allLogos.length > 8) ? 8 : (allLogos.length > 4) ? 4 : 99

    useEffect(() => {
        if (allLogos.length > limit) {
            let interval = null

            interval = setInterval(() => {
                if (activeSet === 0) {
                    setLogos([
                        logos[0],
                        shuffle(urls),
                    ])
                } else {
                    setLogos([
                        shuffle(urls),
                        logos[1],
                    ])
                }

                setTimeout(() => {
                    setActiveSet((activeSet === 1) ? 0 : 1)
                }, 400)
            }, 6000)

            return () => clearInterval(interval)
        }
	}, [ allLogos, activeSet, logos ])

    return logos.length > 0 && (
        <div className={`container mx-auto ${padTop === 'No' ? 'pt-8 pb-12 md:pb-16 xl:pb-28' : 'py-12 md:py-16 xl:py-28'} px-10 bg-white`}>
            <div className="grid grid-cols-1 md:grid-cols-14">
                {verticalLabel && (
                    <div className="hidden xl:block relative">
                        <div className="logo-grid__label absolute top-1/2 left-0 pointer-events-none whitespace-nowrap uppercase text-center font-medium">{verticalLabel}</div>
                    </div>
                )}
                <div className={verticalLabel ? 'col-span-full xl:col-span-12' : 'col-span-full xl:col-span-12 xl:col-start-2'}>
                    {heading && <h2 className="mb-12 md:mb-20 text-2xl md:text-3xl font-bold">{heading}</h2>}
                    <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-14 md:gap-20 text-center opacity-30">
                        {logos[0].map((logo, i) => (i < limit) && (
                            <div key={i} className="logo-grid__logo flex items-center justify-center h-20 relative select-none">
                                <div className={`logo-grid__logo w-full h-full absolute top-0 left-0 bg-center bg-contain bg-no-repeat ${(logo === logos[1]?.[i]) ? 'transition-none' : 'transition'} ${activeSet === 0 ? 'visible opacity-100' : 'invisible opacity-0 pointer-events-none'}`} style={{ backgroundImage: `url(${logo})` }}></div>
                                {(allLogos.length > limit) && (
                                    <div className={`logo-grid__logo w-full h-full absolute top-0 left-0 bg-center bg-contain bg-no-repeat ${(logo === logos[1]?.[i]) ? 'transition-none' : 'transition'} ${activeSet === 1 ? 'visible opacity-100' : 'invisible opacity-0 pointer-events-none'}`} style={{ backgroundImage: `url(${logos[1]?.[i]})` }}></div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LogoGrid
