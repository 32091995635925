import React from "react";
import BackgroundImage from "gatsby-background-image";
import ReactMarkdown from "react-markdown";

import "./style.scss";

const ImageHero = ({ image, heading, video }) => (
  <div className="relative bg-black">
    <BackgroundImage
      tag="background"
      className="w-full h-full absolute top-0 left-0 bg-black bg-cover bg-right bg-no-repeat z-0"
      fluid={[
        image.lowRes,
        {
          ...image.highRes,
          media: "(min-width: 768px)",
        },
      ]}
      style={{ position: null }}
    />
    {video?.video?.mp4Url && (
      <video
        loop
        autoPlay
        muted
        playsInline
        className="hidden md:block w-full h-full absolute top-0 left-0 z-20"
      >
        <source src={video?.video?.mp4Url} type="video/mp4" />
      </video>
    )}
    <div
      className={`py-32 ${
        video?.video?.mp4Url
          ? "image-hero__video-padding video md:pb-0 xl:pb-0"
          : "md:py-48 xl:py-56"
      } relative`}
    >
      <div className="w-full h-full absolute top-0 left-0 bg-black opacity-30 z-10"></div>
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-30 container mx-auto px-10">
        <div className="grid xl:grid-cols-14">
          <div className="xl:col-span-12 xl:col-start-2">
            <h1 className="line-styled-heading max-w-2xl relative text-4xl md:text-5xl lg:text-6xl text-white font-extrabold z-20">
              <ReactMarkdown children={heading} />
            </h1>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ImageHero;
