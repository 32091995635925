import React, { useState } from "react"
import { useLocation } from "@reach/router"
import { navigate } from "gatsby"
import queryString from "query-string"

import { useSearchState } from "../../context"

import Text from "../Inputs/Text"
import Icon from "../Icon"

const PostSearchBar = ({ placeholder, showSocials }) => {
  const { query, setQuery } = useSearchState()
  const location = useLocation()
  const { s } = queryString.parse(location.search)
  const [_query, set_Query] = useState(query || "")

  const handleSubmit = (e) => {
    e.preventDefault()
    setQuery(_query)
  }

  return (
    <div className="pb-12 pt-8 md:py-8 z-20">
      <div className="container mx-auto px-10">
        <div className="grid grid-cols-14">
          <div className="col-span-full xl:col-span-12 xl:col-start-2">
            <div className="md:flex md:flex-row items-center justify-between">
              <form
                className="flex-1 flex items-center"
                onSubmit={(e) => handleSubmit(e)}
              >
                <Text
                  {...{
                    name: "s",
                    placeholder,
                    value: _query,
                    className:
                      "flex-1 py-4 -mt-4 mb-4 md:mb-0 text-2xl text-black border-aldo",
                    placeholderClass: "placeholder-black",
                    changeHandler: ({ currentTarget: { value } }) =>
                      set_Query(value),
                  }}
                />
                <button type="submit" className="ml-4">
                  <Icon name="search" />
                </button>
              </form>
              {showSocials && (
                <div className="flex-1 flex flex-wrap items-center justify-start md:justify-end text-left md:text-right">
                  <p className="md:hidden lg:block w-full md:w-auto mb-4 md:mb-0 text-sm">
                    Follow nDreams to keep updated
                  </p>
                  <a
                    href="https://twitter.com/ndreams"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="md:ml-4 lg:ml-6 hover:text-secondary transition duration-200 w-6"
                  >
                    <Icon name="twitter" />
                    <span className="hidden">Twitter</span>
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCI5twGy4kDS1xf141wZP5jg"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="ml-4 lg:ml-6 hover:text-secondary transition duration-200"
                  >
                    <Icon name="youtube" />
                    <span className="hidden">YouTube</span>
                  </a>
                  <a
                    href="https://www.facebook.com/nDreamsVR/"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="ml-4 lg:ml-6 hover:text-secondary transition duration-200"
                  >
                    <Icon name="facebook" />
                    <span className="hidden">Facebook</span>
                  </a>
                  <a
                    href="https://www.instagram.com/ndreams_vr/?hl=en"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="ml-4 lg:ml-6 hover:text-secondary transition duration-200"
                  >
                    <Icon name="instagram" />
                    <span className="hidden">Instagram</span>
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PostSearchBar
