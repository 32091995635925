import React, { useEffect, useState } from "react"
import ReactMarkdown from "react-markdown"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import cx from "classnames"

const InvestmentNode = ({
  hoverHandler,
  title,
  body,
  inlineLink,
  i,
  active,
  customInlineLinkText,
}) => {
  const generateLink = (type, slug) => {
    switch (type) {
      case "DatoCmsPage":
        return `/${slug}`
      case "DatoCmsPost":
        return `/news/${slug}`
      case "DatoCmsGame":
        return `/games/${slug}`
      default:
        return `/${slug}`
    }
  }

  const linkPrefix = inlineLink
    ? generateLink(inlineLink.internal.type, inlineLink.slug)
    : null

  return (
    <div
      className={`flex cursor-pointer flex-row z-30 flex-nowrap items-start -ml-2 ${
        active === i ? "text-black" : "text-aldo"
      }`}
      onMouseOver={hoverHandler}
    >
      <span
        className={`w-[22px] h-[22px] block ${
          active === i ? "mt-1 lg:mt-3" : "mt-0.5 lg:mt-2"
        }`}
      >
        <svg
          viewBox="0 0 22 22"
          width="22"
          className="block"
          height="22"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="11"
            cy="11"
            r="10"
            stroke="black"
            stroke-width={active === i ? "1" : "0"}
            fill="none"
          />
          <circle
            cx="11"
            cy="11"
            r={active === i ? "5" : "3"}
            fill={active === i ? "#000" : "#bababa"}
          />
        </svg>
      </span>

      <div className={`ml-6 transform`}>
        <h3
          className={`text-3xl transition-all md:text-4xl lg:text-5xl xl:text-5xl font-extrabold uppercase mb-2`}
        >
          {title}
        </h3>
        {/* Swap Source to Children across application for deprecation warning */}
        <ReactMarkdown
          children={body}
          className={`${
            active === i ? "block lg:opacity-100" : "hidden lg:opacity-0"
          } transition-opacity`}
        />
        {inlineLink ? (
          <Link
            to={linkPrefix}
            className={cx(
              `transition-all duration-300 underline text-secondary hover:text-primary`,
              {
                "block lg:opacity-100": active === i,
                "hidden lg:opacity-0": active !== i,
              }
            )}
          >
            {customInlineLinkText || "Find Out More"}
          </Link>
        ) : null}
      </div>
    </div>
  )
}

const InvestingInYou = ({ title, investments = [] }) => {
  const [active, setActive] = useState(0)
  const [paused, setPaused] = useState(false)

  useEffect(() => {
    const interval = setInterval(() => {
      if (!paused) updateActive(active + 1)
    }, 10000)

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  })

  const updateActive = (newIndex) => {
    if (newIndex < 0) newIndex = investments.length
    else if (newIndex >= investments.length) newIndex = 0
    setActive(newIndex)
  }

  return (
    <div className={`relative investing bg-white transition-all`}>
      <div className="container mx-auto px-10 relative md:grid md:grid-cols-14">
        <div />
        <div className="col-span-full xl:col-span-12 grid grid-cols-1 lg:grid-cols-2">
          <div className="pt-12 md:pt-16 xl:py-28 lg:pr-16">
            <h2
              className={`career-styled-heading text-5xl md:text-4xl lg:text-5xl xl:text-6xl font-extrabold uppercase text-black mb-12`}
            >
              <ReactMarkdown children={title} />
            </h2>
            <div className="relative">
              {investments.map(({ image }, i) => {
                const investmentImage = getImage(image)
                return (
                  <GatsbyImage
                    image={investmentImage}
                    alt={image.alt ? image.alt : "Investment Image"}
                    className={`${
                      active === i
                        ? "opacity-100"
                        : "opacity-0 !absolute top-0 left-0"
                    }`}
                  />
                )
              })}
            </div>
          </div>
          <div className="relative py-16 md:py-20 xl:py-28 lg:flex lg:flex-row">
            <span className="absolute top-0 left-0.5 h-full w-0.5 border-l-2 border-dashed border-black opacity-20 block z-0" />
            <div className=" flex flex-col gap-12 lg:gap-16 my-auto h-auto">
              {investments.map((investment, i) => (
                <InvestmentNode
                  key={i}
                  {...{
                    hoverHandler: () => setActive(i),
                    i,
                    active,
                    ...investment,
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InvestingInYou
