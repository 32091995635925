import React, { useState } from 'react'
import Img from 'gatsby-image'

import { useLightboxState } from '../../../../context'

import Link from '../../../Link'
import LinkWithIcon from '../../../LinkWithIcon'
import Icon from '../../../Icon'

import './style.scss'

const MediaShowcaseVideos = ({
    videos = [],
}) => {
    const { setLightboxOpen, setLightboxVideo } = useLightboxState()
    const [ direction, setDirection ] = useState(1)
    const [ index, setIndex ] = useState(0)

    const handleNavigation = () => {
        const offset = (window.innerWidth > 768) ? 3 : 2

        if (index === 1 && direction === 0) {
            setDirection(1)
        } else if (index === (videos.length - offset) && direction === 1) {
            setDirection(0)
        }

        if (direction === 0) setIndex(Math.max(0, index - 1))
        if (direction === 1) setIndex(Math.min(videos.length - (offset-2), index + 1))
    }

    const handleVideoClick = (video) => {
        setLightboxVideo(video)
        setLightboxOpen(true)
    }

    return (
        <div className="mt-8 grid grid-cols-14 gap-4">
            <div className="hidden xl:block relative">
                <div className="media-showcase-image-carousel__label absolute top-1/2 left-0 pointer-events-none whitespace-nowrap uppercase text-center font-medium text-white">Videos</div>
            </div>
            <div className="col-span-12 md:col-span-13 xl:col-span-11 xl:col-start-2 overflow-hidden">
                <div className={`media-showcase-image-carousel__slider grid grid-flow-col grid-cols-14 gap-4 col-start-1 2xl:col-start-2 overflow-x-visible transition duration-500 slide-${index}`}>
                    {videos.map(({ video, image }, i) => (
                        <div key={i} className="link-with-icon-hover relative">
                            <Link clickHandler={() => handleVideoClick(video)}>
                                <Img fluid={image.fluid} />
                                <LinkWithIcon icon="play" color="mi-light" className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" clickHandler={() => true} />
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
            {(videos.length > 1) && (
                <div className="col-span-2 md:col-span-1 flex flex-col items-end justify-center col-span-1 text-right text-white select-none">
                    <div className="py-8 cursor-pointer" onClick={() => handleNavigation()}>
                        <Icon name={(direction === 1) ? 'arrowRight' : 'arrowLeft'} />
                    </div>
                </div>
            )}
        </div>
    )
}

export default MediaShowcaseVideos
