import React, { useState } from "react"
import GatsbyImage from "gatsby-image"
import { getImage } from "gatsby-plugin-image"
import cx from "classnames"
import Icon from "../Icon"
import SVG from "react-inlinesvg"

const WhatWeOfferCard = ({ heading, description, icon }) => {
  const [active, setActive] = useState(false)
  const [hover, setHover] = useState(false)
  const _icon = getImage(icon.gatsbyImageData)
  return (
    <div
      onClick={() => {
        setActive(!active)
      }}
      onMouseEnter={() => {
        setHover(true)
      }}
      onMouseLeave={() => {
        setHover(false)
      }}
      className={cx(
        "clip-border-tr-bl h-fit min-h-[198px] group relative cursor-pointer  bg-transparent col-span-1 p-6 flex flex-col items-center gap-4  text-ellipsis  overflow-hidden",
        {
          "clip-border-tr-bl-white": !!hover,
          // request from client, made clear to them if content changes this could break
          "md:min-h-[339px] lg:min-h-[411px] 2xl:min-h-[339px]": !!active,
          "h-[198px]": !active,
        }
      )}
    >
      <div className="relative w-16">
        {icon.format === "svg" ? (
          <SVG
            src={icon.url}
            {...{
              className: cx(
                "text-light-boulder group-hover:text-white transition-all duration-300",
                { "!text-secondary": !!active }
              ),
            }}
          />
        ) : (
          <GatsbyImage
            image={_icon}
            className={cx(
              "text-light-boulder group-hover:text-white transition-all duration-300",
              { "!text-secondary": !!active }
            )}
          />
        )}
      </div>
      <p className="line-clamp-2 text-center text-light-boulder group-hover:text-white transition-all duration-300 text-3xl uppercase font-bold">
        {heading}
      </p>
      {active && (
        <p className={cx("text-center text-light-boulder text-ellipsis")}>
          {description}
        </p>
      )}

      <div className="absolute right-4 top-4 flex justify-center items-center w-8 h-8">
        <span
          className={cx(
            "w-[1px] h-full rotate-0 bg-light-boulder group-hover:bg-white transition-all duration-300",
            { "!rotate-90": !!active }
          )}
        ></span>
        <span className="w-[1px] h-full rotate-90 bg-light-boulder group-hover:bg-white transition-all duration-300"></span>
      </div>
    </div>
  )
}

export default WhatWeOfferCard
