import React from "react"
import ReactPlayer from "react-player"

const Video = ({ isExternalVideo, internalVideo, externalVideo }) => {
  const videoProps = {
    controls: true,
  }
  return (
    <div className="bg-black py-12">
      <div className="container px-10 grid grid-cols-1 md:grid-cols-14 mx-auto">
        <div className="xl:col-span-12 xl:col-start-2 mx-auto col-span-full object-cover w-full h-full aspect-video">
          <ReactPlayer
            url={
              isExternalVideo ? externalVideo.url : internalVideo.video.mp4Url
            }
            width="100%"
            height="100%"
            className=""
            {...videoProps}
          />
        </div>
      </div>
    </div>
  )
}

export default Video
