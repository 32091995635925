import React, { useEffect, useState } from "react";
import Icon from "../Icon";
import { Swiper, SwiperSlide } from "swiper/react";
import { A11y } from "swiper";
import cx from "classnames";
import SVG from "react-inlinesvg";
import "swiper/css";

const AcademyCard = ({ title, icon, description, active, clickHandler }) => {
  return (
    <div
      className={cx(
        `cursor-pointer h-fit transition-all duration-300 hover:scale-105 group overflow-visible academy-card-clip bg-white`,
        {
          "!scale-105": active,
        }
      )}
      onClick={() => clickHandler()}
    >
      <fieldset
        className={cx(
          "p-8 academy-card-clip__inner transition-all duration-300",
          {
            "bg-white text-black": active,
            "bg-black text-white hover:text-black hover:bg-white": !active,
          }
        )}
      >
        <SVG
          src={icon.url}
          alt={icon.alt ? icon.alt : `${title} Icon`}
          className={cx(
            "max-w-[125px] mx-auto group-hover:text-primary transition-all duration-300",
            {
              "text-primary": active,
              "text-secondary": !active,
            }
          )}
        />
        <h2 className="uppercase font-bold text-2xl md:text-3xl lg:text-xl xl:text-2xl my-8">
          {title}
        </h2>
        {!active ? (
          <span className="flex items-center justify-center gap-4 flex-nowrap mx-auto text-center">
            <Icon
              name="plus"
              className={cx(
                `group-hover:rotate-90 transition-all duration-300`,
                {
                  "fill-primary text-primary": active,
                  "fill-secondary": !active,
                }
              )}
            />
            Learn more
          </span>
        ) : null}
        {active ? <figcaption>{description}</figcaption> : null}
      </fieldset>
    </div>
  );
};

const AcademyHowItWorks = ({
  topText,
  bottomText,
  boldStrapLine,
  academyValues,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [desktopTab, setDesktopTab] = useState(null);

  return (
    <div className="bg-black py-12 lg:pt-0 md:pb-16 relative overflow-x-hidden">
      <div className="xl:container mx-auto lg:px-10 grid gap-8 grid-cols-1 md:grid-cols-14 text-white text-center intro-trigger">
        {topText ? (
          <p className="px-10 xl:px-0 text-xl col-span-full xl:col-span-10 xl:col-start-3 lg:text-3xl lg:leading-8">
            {topText}
          </p>
        ) : null}
        {bottomText ? (
          <p className="px-10 xl:px-0 text-md col-span-full xl:col-span-8 xl:col-start-4 lg:text-lg leading-6">
            {bottomText}
          </p>
        ) : null}
        {boldStrapLine ? (
          <p className="font-bold col-span-full text-center">{boldStrapLine}</p>
        ) : null}

        <div className="w-full mx-auto col-span-full xl:col-start-2 xl:col-span-12 mt-10 overflow-x-visible lg:!px-10 hidden lg:grid grid-cols-4 gap-6">
          {academyValues.map((value, _i) => {
            return (
              <AcademyCard
                {...{
                  clickHandler: () => setDesktopTab(_i),
                  active: desktopTab === _i,
                  ...value,
                }}
              />
            );
          })}
        </div>

        <Swiper
          modules={[A11y]}
          spaceBetween={35}
          slidesPerView={1.5}
          centeredSlides={true}
          loop={true}
          className="w-full mx-auto col-span-full xl:col-start-2 xl:col-span-12 mt-10 overflow-x-visible lg:!px-10 block lg:!hidden"
          onSlideChange={({ realIndex }) => setCurrentSlide(realIndex)}
          onSwiper={() => setCurrentSlide(0)}
          breakpoints={{
            550: {
              spaceBetween: 35,
              slidesPerView: 1.5,
              centeredSlides: true,
              loop: true,
            },
            768: {
              spaceBetween: 50,
              slidesPerView: 1.8,
              centeredSlides: true,
              loop: true,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 40,
              centeredSlides: false,
              loop: false,
            },
            1280: {
              spaceBetween: 80,
              slidesPerView: 4,
              spaceBetween: 20,
              centeredSlides: false,
              loop: false,
            },
          }}
        >
          {academyValues.map((value, _i) => {
            return (
              <SwiperSlide className="py-10">
                <AcademyCard
                  {...{
                    clickHandler: () => setCurrentSlide(_i),
                    active: currentSlide === _i,
                    ...value,
                  }}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};
export default AcademyHowItWorks;
