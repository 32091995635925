import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import cx from "classnames";

import Icon from "../Icon";
import { getImage, GatsbyImage } from "gatsby-plugin-image";

import CircleCheckbox from "../Inputs/CircleCheckbox";

import "./styles.scss";

const CareersStudioFilter = ({
  studios = [],
  setStudios,
  color = "light",
  size = "md",
  className,
}) => {
  const [open, setOpen] = useState(false);
  const {
    datoCmsGlobalNav: {
      ndreams,
      studio,
      elevation,
      orbital,
      nearlight,
      ndreamsName,
      elevationStudioName,
      orbitalStudioName,
      farnboroughStudioName,
      nearlightStudioName,
    },
  } = useStaticQuery(graphql`
    query filterLogos {
      datoCmsGlobalNav {
        ndreams: ndreamsLogo {
          gatsbyImageData(placeholder: BLURRED)
        }
        elevation: elevationLogo {
          gatsbyImageData(placeholder: BLURRED)
        }
        studio: farnboroughLogo {
          gatsbyImageData(placeholder: BLURRED)
        }
        orbital: orbitalLogo {
          gatsbyImageData(placeholder: BLURRED)
        }
        nearlight: nearlightLogo {
          gatsbyImageData(placeholder: BLURRED)
        }
        ndreamsName
        elevationStudioName
        orbitalStudioName
        nearlightStudioName
        farnboroughStudioName
      }
    }
  `);

  const studioNames = [
    {
      name: ndreamsName,
      image: getImage(ndreams.gatsbyImageData),
    },
    {
      name: farnboroughStudioName,
      image: getImage(studio.gatsbyImageData),
    },
    {
      name: elevationStudioName,
      image: getImage(elevation.gatsbyImageData),
    },
    {
      name: orbitalStudioName,
      image: getImage(orbital.gatsbyImageData),
    },
    {
      name: nearlightStudioName,
      image: getImage(nearlight.gatsbyImageData),
    },
  ];

  const options = [
    {
      value: "",
      label: "Select team",
    },
    ...studioNames.map((studio) => ({
      value: studio.name,
      label: studio.name,
      image: studio.image,
    })),
  ];

  return (
    <div
      className={cx(
        `studio-select flex items-center justify-between relative border-b border-dashed ${
          color === "light" ? "border-black" : "border-white"
        } cursor-pointer size-${size} studio-select-${color} z-40`,
        className
      )}
      onClick={() => setOpen(!open)}
    >
      <div
        className={cx(`studio-select-none`, {
          "py-4 text-2xl w-80 md:text-2xl": size === "lg",
          "text-base": size === "md",
        })}
      >
        {studios.length > 0 ? (
          <span>{studios.toString().replaceAll(",", ", ")}</span>
        ) : (
          <span className="md:text-3xl">Select team</span>
        )}
      </div>

      <Icon
        name="angleDown"
        className={`transform origin-center ${
          open ? "rotate-180" : "rotate-0"
        }`}
      />

      <div
        className={`studio-select__options absolute w-full top-[102%] left-0 bg-bare ${
          open ? "block" : "hidden"
        }`}
      >
        <div className="studio-select__shadow absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-3xl" />
        {options.map((option, i) => {
          return (
            <div
              key={i}
              className={cx(
                `studio-select__option flex justify-center items-center relative border-b border-dashed group border-boulder cursor-pointer hover:!bg-gallery ${
                  !option.image ? "bg-gallery py-3" : "bg-bare py-6"
                } ${studios.includes(option.label) ? "!bg-gallery" : "bg-bare"}`
              )}
              onClick={() => setStudios(option.value)}
            >
              {!option.image && (
                <div className="px-4 flex items-center justify-between w-full group-hover:text-secondary">
                  <span className="text-left w-full font-bold py-0 my-0">
                    {option.label}
                  </span>
                  <Icon
                    {...{
                      name: "cross",
                      className: "text-secondary",
                    }}
                  />
                </div>
              )}
              {option.image && (
                <CircleCheckbox
                  checked={studios.includes(option.label) ? true : false}
                  className="absolute left-4"
                />
              )}
              <GatsbyImage
                image={option.image}
                alt={option.label}
                className={`filter brightness-50 w-full h-full max-w-[200px] !grayscale`}
                objectFit="contain"
                objectPosition="center"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CareersStudioFilter;
