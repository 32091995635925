import { isBefore, parseISO } from "date-fns"
import { useStaticQuery, graphql, Link } from "gatsby"
import React, { useMemo, useState } from "react"
import Button from "../Button"
import Icon from "../Icon"
import cx from "classnames"
import "./styles.scss"

const GetAcademyCareers = () => {
  const { allWorkableJob } = useStaticQuery(graphql`
    query GetAcademyCareers {
      allWorkableJob(
        filter: {
          department_hierarchy: {
            elemMatch: { name: { eq: "nDreams Academy" } }
          }
          department: { ne: "DUMMY" }
        }
      ) {
        edges {
          node {
            id
            application_url
            benefits
            department
            description
            # employment_type
            requirements
            shortcode
            title
            created_at
            department_hierarchy {
              id
              name
            }
          }
        }
      }
    }
  `)

  return allWorkableJob.edges
}

const AcademyCareerCard = ({
  title,
  shortcode,
  department,
  className,
  hide = false,
}) => (
  <div
    className={cx(
      "group transform origin-center hover:scale-105 hover:z-20 transition-all duration-300 hover:bg-black",
      className,
      { hidden: hide }
    )}
    data-department={department}
  >
    <Link
      to={`/careers/${shortcode}`}
      className="flex flex-col min-h-full overflow-hidden"
    >
      <div className="flex-1 p-6 border border-white bg-white group-hover:border-b-black group-hover:bg-black transition-all duration-300">
        <div className="mb-8 flex items-start justify-between">
          <h3 className="text-2xl text-black font-bold group-hover:text-white transition-all duration-300">
            {title}
          </h3>
          <Icon
            name={department ? department.toLowerCase() : department}
            className="ml-4 text-primary"
          />
        </div>
      </div>
      <span className="border-dashed border-b bg-white border-black group-hover:border-white group-hover:bg-black transition-all duration-300" />
      <div className="academy-career-btm bg-white">
        <fieldset className="academy-career-btm__inner p-6 w-full h-full bg-white group-hover:bg-black transition-all duration-300">
          <figcaption className="text-primary text-lg font-bold uppercase z-10 group-hover:text-white transition-all duration-300">
            Find out more
          </figcaption>
        </fieldset>
      </div>
    </Link>
  </div>
)

const AcademyCareersArchive = ({ heading, showFirst: first = 8 }) => {
  const [visible, setVisible] = useState(first)
  const careers = useMemo(() => GetAcademyCareers() || [])
  console.log({ careers })
  return (
    <div className="bg-black pt-20 pb-32 md:pb-36 xl:pb-40 relative">
      <aside className="academy__background w-full h-full absolute bottom-0 left-0 z-0 overflow-hidden" />
      <div className="container mx-auto px-10 grid grid-cols-1 md:grid-cols-14">
        <h2 className="flex-1 text-3xl lg:text-4xl xl:text-5xl text-secondary col-span-full xl:col-span-12 xl:col-start-2 uppercase font-bold">
          {heading}
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-10 lg:mt-12 row-start-2 col-span-full xl:col-span-12 xl:col-start-2">
          {careers.length > 0 ? (
            careers.map(({ node }, index) => (
              <AcademyCareerCard
                key={index}
                {...{ hide: index >= visible, ...node }}
              />
            ))
          ) : (
            <p className="col-span-full text-left w-full text-lg md:text-xl text-white">
              No open vacancies, please check back in the future.
            </p>
          )}
        </div>
      </div>
      {visible < careers.length ? (
        <span className="block mt-8 md:mt-12 text-center">
          <Button
            className="mx-auto hover:bg-secondary hover:border-secondary"
            clickHandler={() => setVisible(visible + first)}
          >
            Load more
          </Button>
        </span>
      ) : null}
      {/* <Button>Load More</Button> */}
    </div>
  )
}

export default AcademyCareersArchive
