import React from 'react';
import cx from 'classnames';

import Icon from '../../Icon';

const CircleCheckbox = ({
    name,
    label,
    checked = false,
    changeHandler,
    className
}) => {
    const handleChange = (e) => {
        e.preventDefault();
        typeof changeHandler === 'function' && changeHandler();
    }

    return (
        <div className={cx(className)}>
            <label htmlFor={name} className={cx('inline-flex items-center select-none cursor-pointer')} onClick={(e) => handleChange(e)}>
				<input
					{...{
						type: 'checkbox',
						name,
						checked,
						className: 'hidden',
						onChange: () => true,
					}}
				/>
				<span className={`flex items-center justify-center h-6 w-6 mr-4 border-2 border-aldo border-dashed rounded-full bg-bare group-hover:bg-transparent ${checked ? 'text-black border-boulder bg-transparent' : 'text-white'}`}>
                    <div className={`w-full h-full rounded-full flex items-center justify-center`}>
    					<Icon name="check" className={`${checked ? 'text-secondary' : 'text-transparent'}`} />

                    </div>
				</span>
				{label && <span>{label}</span>}
			</label>
        </div>
    )
}

export default CircleCheckbox;