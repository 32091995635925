import fistBump from './FistBump'
import planet from './Planet'
import reload from './Reload'
import superhero from './Superhero'
import heart from './Heart'

export default {
    fistBump,
    planet,
    reload,
    superhero,
    heart,
}
