import React from 'react';
import ReactMarkdown from 'react-markdown';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import './styles.scss';

const PerksAndBenefits = ({ title, sideText, perksList, perksImage }) => {
    const image = getImage(perksImage);
    return (
        <div className="relative perks-and-benefits bg-gradient-to-b from-bare to-gallery pt-12 md:pt-16 xl:pt-28">
            <div className="perks-and-benefits__background w-full h-full absolute top-0 left-0 z-0 overflow-hidden" />
            <div className="container mx-auto lg:px-10 relative">
                <div className="md:grid md:grid-cols-14">
                    <div className="hidden lg:block">
                        <div className="vert-label">{sideText}</div>
                    </div>
                    <div className="col-span-full xl:col-span-13">
                        <div className="grid grid-cols-1 lg:grid-cols-2">
                            <div className="px-10 lg:px-0 lg:pb-12 xl:pb-28 lg:pr-10 xl:pr-20">
                                <h2 className={`line-styled-heading text-5xl md:text-4xl lg:text-5xl xl:text-6xl font-extrabold uppercase mb-8`}><ReactMarkdown children={title} /></h2>
                                <ReactMarkdown children={perksList} className="perks-and-benefits__list text-lg" />
                            </div>
                            <div className="pt-24 lg:flex lg:items-end lg:justify-end">
                                <GatsbyImage image={image} alt={perksImage.alt ? perksImage.alt : 'Perks at nDreams'} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PerksAndBenefits;