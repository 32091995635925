import React from 'react';
import ReactMarkdown from 'react-markdown';
import LinkWithIcon from '../LinkWithIcon';

const WorldsBest = ({ body, verticalText, items = [] }) => {

    return (
        <div className="relative">
            <div className="py-10 md:py-16 xl:py-28 container mx-auto relative px-10 lg:px-0">
                <div className="grid grid-cols-1 lg:grid-cols-14">
                    <div className="hidden xl:block">
                        <div className="vert-label">{verticalText}</div>
                    </div>
                    <div className="col-span-full xl:col-span-12">
                        <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between">
                            <ReactMarkdown children={body} className="max-w-[575px] mb-8 lg:mb-0" />
                            <LinkWithIcon {...{
                                text: 'Get in touch',
                                icon: 'arrowRight',
                                clickHandler: () => {
                                    const form = document.querySelector('#contactForm');
                                    return form.scrollIntoView({ behavior: 'smooth', block: 'start' })
                                }
                            }} />
                        </div>
                        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mt-12">
                            {items.map(({ title, image }, i) => {
                                return (
                                    <div className="border-aldo border border-dashed p-8 flex justify-between flex-col">
                                        <h3 className="uppercase blue-styled-heading text-4xl mb-8"><ReactMarkdown children={title} /></h3>
                                        <div className="flex items-center justify-center px-20 pb-8">
                                            <img src={image.url} alt={title} width={225} height={225} />
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WorldsBest;