import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { useRef } from "react";
import { convertToBgImage } from "gbimage-bridge";
import cx from "classnames";
import { Link } from "gatsby";
import Icon from "../Icon";
import { Swiper, SwiperSlide } from "swiper/react";
import { A11y } from "swiper";
import "swiper/css";

const Slide = ({
  image,
  testimonialBody,
  testimonialName,
  testimonialPosition,
  testimonialLink,
  className,
}) => {
  const _image = getImage(image.gatsbyImageData);
  const _background = convertToBgImage(_image);

  return (
    <li
      className={cx(`w-full h-full bg-black bg-opacity-30 relative`, className)}
    >
      <GatsbyImage
        image={_image}
        objectFit="cover"
        objectPosition={'center'}
        className="h-[300px] min-h-[300px] md:min-h-[400px] md:h-[400px] w-full lg:h-full"
      />
      <figure className="max-w-[550px] bg-black p-8 absolute bottom-0 left-20 hidden lg:block">
        <figcaption>"{testimonialBody}"</figcaption>
        <div className="flex flex-row flex-nowrap gap-4 mt-4 justify-between">
          <figure>
            <figcaption className="font-bold text-secondary">
              {testimonialName}
            </figcaption>
            {testimonialPosition ? (
              <figcaption>{testimonialPosition}</figcaption>
            ) : null}
          </figure>
          {testimonialLink ? (
            <Link
              to={`/${testimonialLink.slug}`}
              className="py-3 px-6 bg-primary text-white uppercase font-bold hover:bg-secondary transition-all duration-200"
            >
              Read More
            </Link>
          ) : null}
        </div>
      </figure>
      <fieldset className="lg:hidden p-4 sm:p-6 bg-black">
        <figcaption>"{testimonialBody}"</figcaption>
        <div className="flex flex-row flex-nowrap gap-4 mt-6 justify-between">
          <figure>
            <figcaption className="font-bold text-secondary">
              {testimonialName}
            </figcaption>
            {testimonialPosition ? (
              <figcaption>{testimonialPosition}</figcaption>
            ) : null}
          </figure>
          {testimonialLink ? (
            <Link
              to={`/${testimonialLink.slug}`}
              className="py-3 px-6 text-center bg-primary text-white uppercase font-bold hover:bg-secondary transition-all duration-200"
            >
              Read More
            </Link>
          ) : null}
        </div>
      </fieldset>
    </li>
  );
};

const AcademySlider = ({ testimonials }) => {
  const swiperRef = useRef(null);

  const handlePrev = () => {
    if (swiperRef.current) {
      return swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current) {
      return swiperRef.current.swiper.slideNext();
    }
  };

  return (
    <div className="bg-gradient-to-t from-secondary via-black to-black xl:pb-28 pb-36 md:pb-40 text-white overflow-x-hidden">

      <div className="container px-10 grid grid-cols-1 md:grid-cols-14 mx-auto">
        <div className="col-span-full xl:col-span-12 xl:col-start-2 list-none academy-slider-clip relative">
          <Swiper
            ref={swiperRef}
            modules={[A11y]}
            spaceBetween={0}
            slidesPerView={1}
            centeredSlides={true}
            loop={true}
            className="lg:h-[550px]"
          >
            {testimonials.map((testimonial, i) => (
              <SwiperSlide className="lg:h-full">
                <Slide key={i} {...{ ...testimonial }} />
              </SwiperSlide>
            ))}
          </Swiper>
          <aside className="flex flex-row gap-6 absolute top-[125px] md:top-[175px] lg:hidden left-4 md:left-8 z-20">
            <span
              className="link-with-icon inline-flex items-center text-lg font-bold uppercase cursor-pointer select-none"
              onClick={() => handlePrev()}
            >
              <span className="link-with-icon__circle inline-flex items-center justify-center cursor-pointer select-none relative">
                <Icon name="arrowLeft" className="link-with-icon__icon" />
              </span>
            </span>
          </aside>
          <aside className="flex flex-row gap-6 absolute top-[125px] md:top-[175px] lg:hidden right-4 md:right-8 z-20">
            <span
              className="link-with-icon inline-flex items-center text-lg font-bold uppercase cursor-pointer select-none"
              onClick={() => handleNext()}
            >
              <span className="link-with-icon__circle inline-flex items-center justify-center cursor-pointer select-none relative">
                <Icon name="arrowRight" className="link-with-icon__icon" />
              </span>
            </span>
          </aside>
          {/* Desktop Arrows Below */}
          <aside className="hidden lg:flex flex-row gap-6 absolute bottom-6 right-20 z-20">
            <span
              className="link-with-icon inline-flex items-center text-lg font-bold uppercase cursor-pointer select-none"
              onClick={() => handlePrev()}
            >
              <span className="link-with-icon__circle inline-flex items-center justify-center cursor-pointer select-none relative">
                <Icon name="arrowLeft" className="link-with-icon__icon" />
              </span>
            </span>
            <span
              className="link-with-icon inline-flex items-center text-lg font-bold uppercase cursor-pointer select-none"
              onClick={() => handleNext()}
            >
              <span className="link-with-icon__circle inline-flex items-center justify-center cursor-pointer select-none relative">
                <Icon name="arrowRight" className="link-with-icon__icon" />
              </span>
            </span>
          </aside>
        </div>
      </div>
    </div>
  );
};

export default AcademySlider;
