import React, { useState } from 'react';

import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge'
import BackgroundImage from 'gatsby-background-image';

import Icon from '../Icon';
import ReactMarkdown from 'react-markdown';

import './styles.scss';

const SupportCard = ({ title, body, backgroundImage }) => {

    const [flip, setFlip] = useState(false);

    const image = getImage(backgroundImage.gatsbyImageData);
    const background = convertToBgImage(image);

    return (
        <div className={`support-card ${flip && 'flipped'} min-h-[350px] cursor-pointer`} onClick={() => setFlip(!flip)}>
            <div className="support-card__inner">
                <div className={`min-h-[350px] text-white support-card__front relative`}>
                    <BackgroundImage
                        Tag="div"
                        {...background}
                        style={{ minHeight: '350px' }}
                        preserveStackingContext
                    >
                        <div className="flex items-start p-8 justify-between flex-row mb-8 min-h-[350px]">
                            <h3 className="uppercase font-extrabold text-2xl xl:text-3xl max-w-[250px]">{title}</h3>
                            <Icon {...{
                                name: 'plus',
                                className: 'font-extrabold text-2xl'
                            }} />
                        </div>
                    </BackgroundImage>
                </div>
                <div className={`support-card__back min-h-[350px] p-8`}>
                    <div className="flex items-center justify-between flex-row mb-8">
                        <h3 className="uppercase font-extrabold text-2xl xl:text-3xl max-w-[250px]">{title}</h3>
                        <Icon {...{
                            name: 'minus',
                            className: 'text-black'
                        }} />
                    </div>
                    <ReactMarkdown children={body} />
                </div>
            </div>
        </div>
    )
}

export default SupportCard