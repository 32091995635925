import React from "react"
import cx from "classnames"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import "./styles.scss"
import Icon from "../Icon"
import { useRef, useEffect } from "react"
import gsap from "gsap"
import LinkWithIcon from "../LinkWithIcon"

const PressReleaseCard = ({ title, image, externalLink, hide }) => {
  const cardRef = useRef(null)
  const img = getImage(image.gatsbyImageData)
  const timeline = useRef(gsap.timeline({ paused: true }))
  useEffect(() => {
    if (cardRef.current && timeline.current) {
      const ctx = gsap.context(() => {
        timeline.current.fromTo(
          cardRef.current,
          {
            duration: 0.3,
            clipPath:
              "polygon(40px 0%, 100% 0%, 100% calc(100% - 40px), calc(100% - 40px) 100%, 0% 100%,0% 40px )",
          },
          {
            duration: 0.3,
            clipPath:
              "polygon(0px 0%, 100% 0%, 100% calc(100% - 0px), calc(100% - 0px) 100%, 0% 100%, 0% 0px)",
          }
        )
      })

      return () => ctx.revert()
    }
  }, [])
  return (
    <div
      ref={cardRef}
      className={cx(
        "press-release-card group transform origin-center transition duration-200 relative ",
        { hidden: hide }
      )}
      onMouseEnter={() => timeline.current.play()}
      onMouseLeave={() => timeline.current.reverse()}
    >
      <a
        rel="noreferrer noopenner"
        className="flex flex-col min-h-full overflow-hidden"
        target="_blank"
        href={externalLink}
      >
        <div className="flex-1 press-release-card__inner bg-white group-hover:bg-black transition-all duration-300">
          <div className="w-full aspect-[16/10] relative overflow-hidden">
            <GatsbyImage
              image={img}
              alt="something"
              objectFit="cover"
              objectPosition="center"
              className=" w-full h-full z-0 max-h-screen max-w-none absolute top-0 left-0 group-hover:scale-150 duration-300 transition-all"
            />
          </div>
          <div className="p-6 mb-8 flex items-start justify-between">
            <h3 className="text-2xl text-black group-hover:text-white font-bold leading-tight">
              {title}
            </h3>
          </div>
        </div>
        <div className="p-6 py-4 relative border-t border-dashed border-aldo overflow-hidden bg-white group-hover:bg-black duration-300 transition-all flex gap-8 items-center">
          {/* <span className="relative text-black group-hover:text-white text-lg font-bold uppercase z-10">
            Find out More
          </span>
          <Icon
            name="circledArrowRight"
            className="w-10 sm:w-12 group-hover:text-white"
          /> */}
          <LinkWithIcon
            to={externalLink}
            external
            text="Find out More"
            className="group-hover:!text-white !text-black"
            icon="arrowRight"
            iconClasses="group-hover:text-white link-with-icon__circle-dark"
          />
        </div>
      </a>
    </div>
  )
}

export default PressReleaseCard
