import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'

import { useLightboxState } from '../../context'

import LinkWithIcon from '../LinkWithIcon'
import Icon from '../Icon'

import './style.scss'

const Card = ({
	title,
	slug,
	tagline,
	secondaryColour,
	logo,
	trailer,
	heroImage,
	onMouseEnter,
	onMouseLeave,
	handleVideoClick,
}) => (
	<div className="games-carousel__card hidden md:col-span-2 xl:col-span-1 md:block pb-6 md:pb-0 md:pr-10" { ...{ onMouseEnter, onMouseLeave }}>
		<div className="games-carousel-card__inner pt-full relative bg-aldo">
			<BackgroundImage
				tag="card-background"
				className="w-full h-full top-0 left-0 bg-cover bg-center bg-no-repeat"
				fluid={heroImage.fluid}
				style={{ position: 'absolute' }}
			>
				<div className="games-carousel__card-inner flex flex-col justify-end w-full h-full absolute top-0 left-0 md:opacity-0 md:visibility-hidden md:pointer-events-none transition">
					<div className="w-full h-full absolute top-0 left-0 z-20 opacity-70" style={{ backgroundColor: secondaryColour?.hex }} />
					<div className="relative z-20 text-white">
						<div className="p-8 sm:p-10">
							<h2 className="games-carousel__card-heading max-w-sm text-3xl sm:text-4xl font-extrabold uppercase md:transform md:translate-x-5 md:transition md:delay-75 md:duration-200">{title}</h2>
							{tagline && <p className="hidden sm:block games-carousel__card-tagline mt-8 text-white font-thin text-xl md:text-2xl md:transform md:translate-x-20 md:transition md:delay-75 md:duration-200">{tagline}</p>}
						</div>

						<div className={`flex items-center ${(!trailer?.url) ? 'justify-start' : 'justify-between'} p-8 border-t border-dashed border-white`}>
							<LinkWithIcon color="mi-light" to={`/games/${slug}`} text="See more" icon="arrowRight" />
							{trailer?.url && <LinkWithIcon color="mi-light" clickHandler={() => handleVideoClick(trailer)} text="Play trailer" icon="arrowRight" className="hidden sm:flex" />}
						</div>
					</div>
				</div>
				<Img fluid={logo.fluid} className="games-carousel__card-logo hidden md:block w-10/12 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 md:transition md:duration-200" />
			</BackgroundImage>
		</div>
	</div>
)

const GamesCarousel = ({
	games = [],
}) => {
	const { setLightboxVideo, setLightboxOpen } = useLightboxState()
	const [ index, setIndex ] = useState(0)
	const [ direction, setDirection ] = useState(1)
	const [ active, setActive ] = useState(true)

	const navigate = ({ forceDirection = null }) => {
		const offset = (window.innerWidth > 768) ? (window.innerWidth > 1280) ? 3 : 2 : 1

		if (!forceDirection) {
			if (index === 1 && direction === 0) {
				setDirection(1)
			} else if (index === (games.length - offset) && direction === 1) {
				setDirection(0)
			}
		}

		if (forceDirection === 0 || ((forceDirection === null) && direction === 0)) setIndex(Math.max(0, index - 1))
		if (forceDirection === 1 || ((forceDirection === null) && direction === 1)) setIndex(Math.min(games.length - (offset-1), index + 1))
	}

	const handleVideoClick = (trailer) => {
		setActive(false)
        setLightboxVideo(trailer)
        setLightboxOpen(true)
    }

	const handlePrevClick = () => {
		setActive(false)
		navigate({ forceDirection: 0 })
		setTimeout(() => {
			setActive(true)
		}, 5000)
	}

	const handleNextClick = () => {
		setActive(false)
		navigate({ forceDirection: 1 })
		setTimeout(() => {
			setActive(true)
		}, 5000)
	}

	return (
		<div className="games-carousel py-12 md:py-16 overflow-hidden bg-bare md:bg-white">
			<div className="container mx-auto relative z-20">
            	<div className="grid grid-cols-1 md:grid-cols-14 px-10">
					<div className="hidden xl:block relative">
						<div className={cx('games-carousel__label absolute top-1/2 left-0 pointer-events-none whitespace-nowrap uppercase text-center font-medium', { hide: index > 0 })}>Our VR games</div>
					</div>
					<div className={`games-carousel__slider col-span-full lg:col-span-10 xl:col-span-13 grid grid-cols-1 md:grid-flow-col overflow-x-visible mt-4 md:mt-0 md:transition md:duration-500 slide-${index}`}>
						{ games.map((game, i) => (
							<Card
								{ ...{
									key: i,
									onMouseEnter: () => setActive(false),
									onMouseLeave: () => setActive(true),
									handleVideoClick,
									...game
								} }
							/>
						))}
					</div>
					<div className="flex items-center justify-between col-span-full xl:col-span-12 xl:col-start-2 mt-8 md:mt-12 text-center md:text-right">
						<div className="hidden md:flex items-center">
							<div className="-ml-4 p-4 cursor-pointer" onClick={() => handlePrevClick()}>
								<Icon name="arrowLeft" />
							</div>
							<div className="p-4 ml-4 cursor-pointer" onClick={() => handleNextClick()}>
								<Icon name="arrowRight" />
							</div>
						</div>

						<LinkWithIcon color="mi-dark" to="/games" text="See all games" icon="arrowRight" />
					</div>
				</div>
			</div>
		</div>
	)
}

export default GamesCarousel
