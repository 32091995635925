import Button from "../Button"
import PressReleaseCard from "../PressReleaseCard"

import { useState } from "react"
import "./styles.scss"
import React from "react"
import PublishingTestimonialsSlider from "./PublishingTestimonialsSlider"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"
import ReactPlayer from "react-player"
import cx from "classnames"

const PublishingTestimonials = ({
  heading,
  subHeading,
  sideTag,
  isExternalVideo,
  externalVideo,
  video,
  description,
  backgroundImage,
  slides,
}) => {
  const _image = getImage(backgroundImage.gatsbyImageData)
  const _background = convertToBgImage(_image)
  return (
    <section className="relative">
      <BackgroundImage
        {..._background}
        Tag="div"
        style={{
          backgroundPosition: "top center",
          position: "relative",
        }}
      >
        <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-t from-black  to-transparent z-10"></div>

        <div
          id="publishing-testimonials"
          className="pt-12 md:pt-16 xl:pt-28 bg-[#0d0d0d] bg-opacity-80 relative z-20 w-full"
        >
          <div className="container mx-auto relative px-10 w-full z-20">
            <div className="grid grid-cols-1 md:grid-cols-14 w-full">
              <div className="col-span-full xl:col-span-12 xl:col-start-2 w-full">
                <div className="flex flex-start md:mb-12 items-start gap-10">
                  <div className="hidden md:flex sideways-text uppercase rotate-180 justify-end mr-4 font-semibold text-md mt-8 md:mt-0 text-light-boulder">
                    {sideTag}
                  </div>
                  <div>
                    <h2 className="flex-1 text-3xl md:text-4xl lg:text-5xl font-bold text-secondary uppercase">
                      {heading}
                    </h2>
                    <h2 className="flex-1 text-3xl md:text-4xl lg:text-5xl font-bold text-white uppercase">
                      {subHeading}
                    </h2>
                    <p className="text-light-boulder max-w-xl mt-12 text-lg">
                      {description}
                    </p>
                  </div>
                </div>
                <div className="flex mt-20 w-full">
                  <PublishingTestimonialsSlider {...{ slides }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </BackgroundImage>
      {(isExternalVideo && externalVideo?.url) || video?.video?.mp4Url ? (
        <div className="py-12 md:py-16 xl:py-28 bg-[#0d0d0d] relative">
          <div className="container mx-auto relative px-10">
            <div className="grid grid-cols-1 md:grid-cols-14">
              <div className="col-span-full xl:col-span-12 xl:col-start-2">
                <div
                  className={cx(
                    "md:px-10 relative w-full flex justify-center z-20",
                    { "aspect-video": isExternalVideo }
                  )}
                >
                  <ReactPlayer
                    url={
                      isExternalVideo ? externalVideo.url : video.video.mp4Url
                    }
                    width="100%"
                    height="100%"
                    controls={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <div className="opacity-5 h-full w-full absolute bottom-0 top-0 z-10 pointer-events-none">
        <div
          className="h-full w-full absolute -bottom-0 -left-0  animate-pulse radial-gradient-white"
          role="status"
        ></div>
      </div>
    </section>
  )
}

export default PublishingTestimonials
