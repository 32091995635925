import React from 'react'
import BackgroundImage from 'gatsby-background-image'
import ReactMarkdown from 'react-markdown'

import { getModelSlug } from '../../config/helpers'
import { useLightboxState } from '../../context'

import LinkWithIcon from '../LinkWithIcon'

import './style.scss'

const VideoWithText = ({
    heading,
    image,
    imageText,
    link: {
        slug,
        model: {
            name: modelName,
        },
    },
    linkText,
    video,
    playButtonColour,
    verticalLabel,
}) => {
    const { setLightboxVideo, setLightboxOpen } = useLightboxState()

    const handleVideoClick = () => {
        setLightboxVideo(video)
        setLightboxOpen(true)
    }

    return (
        <div className="video-with-text pt-12 md:py-16 xl:py-28 bg-bare relative overflow-hidden xd:overflow-visible">
            <div className="container mx-auto relative px-10">
                <div className="grid grid-cols-1 md:grid-cols-14">
                    <div className="hidden xl:block">
                        <div className="vert-label">{verticalLabel}</div>
                    </div>
                    <div className="col-span-14 md:col-span-7 xl:col-span-6 md:pr-8 2xl:pr-4">
                        <h2 className="line-styled-heading video-with-text__heading relative text-4xl sm:text-5xl md:text-4xl lg:text-5xl 2xl:text-6xl font-extrabold uppercase"><ReactMarkdown children={heading} /></h2>
                        <LinkWithIcon to={`${getModelSlug(modelName)}/${slug}`} text={linkText} icon="arrowRight" />
                    </div>
                    <div className="col-span-14 md:col-span-7 xl:col-span-6 mt-12 md:mt-0">
                        <div className="link-with-icon-hover -mx-10 md:mx-0 relative cursor-pointer" title={video?.title} onClick={() => handleVideoClick()}>
                            <BackgroundImage
                                tag="thumbnail"
                                className="block w-full pt-9/16 bg-aldo relative bg-cover bg-center bg-no-repeat"
                                fluid={image?.fluid}
                            >
                                {imageText && (
                                    <h3 className="md:hidden lg:block video-with-text__image-text w-full p-8 absolute bottom-0 right-0 text-4xl sm:text-5xl md:text-4xl 2xl:text-5xl text-right text-white font-extrabold uppercase">
                                        <span className="line-styled-heading inline-block max-w-xs sm:max-w-sm md:max-w-xs lg:max-w-sm">{imageText}</span>
                                    </h3>
                                )}
                                <LinkWithIcon clickHandler={() => null} icon="play" color={playButtonColour === 'White' ? 'mi-light' : 'mi-dark'} className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
                            </BackgroundImage>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VideoWithText
